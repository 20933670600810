import React from "react";
import { Card, CardBody } from "reactstrap";
import DataTable from "../../../shared/components/table/dataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EyeIcon from "mdi-react/EyeIcon";
import ChartBoxOutlineIcon from "mdi-react/ChartBoxOutlineIcon";
import { withRouter } from "react-router";

const DevicesTable = ({ tableCardData, history, isLoading }) => {
  const tableHeaders = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "site",
      headerName: "Site",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "eui",
      headerName: "Eui",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "battery_level",
      headerName: "Battery",
      minWidth: 125,
      valueFormatter: (params) => {
        if (params.value == null) return "0%";
        else return `${params.value}%`;
      },
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 10,
      getActions: (params) => [
        <GridActionsCellItem icon={<EyeIcon />} label="View Device" onClick={() => history.push(`/devices/${params.id}`)} showInMenu />,
        <GridActionsCellItem icon={<ChartBoxOutlineIcon />} label="View Dashboard" onClick={() => history.push(`/devices/${params.id}/dashboard`)} showInMenu />,
      ],
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element, index) => {
        array.push({
          id: element.id,
          name: element.name,
          model: element.model.name,
          site: element.site.name,
          location: element.location.name,
          eui: element.eui,
          battery_level: element.battery_level,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} rowOnClickPath={"/devices/id"} tableSize={10} disableFiltering={false} showToolbar={true} />;
};

export default withRouter(DevicesTable);
