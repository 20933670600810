import { combineReducers } from "redux";

//Reducers
import userReducer from "../../containers/user/redux/userReducer";
import deviceReducers from "../../containers/devices/redux/deviceReducers";
import sidebarReducer from "../../shared/components/Layout/redux/sidebarReducer";
import adminReducer from "../../containers/admin/redux/adminReducer";
import dashboardReducer from "../../containers/dashboards/redux/dashboardReducer";
import reportReducers from "../../containers/reports/redux/reportReducers";
import siteReducers from "../../containers/sites/redux/siteReducers";
import locationReducers from "../../containers/locations/redux/locationReducers";

const appReducer = combineReducers({
  sidebar: sidebarReducer,
  user: userReducer,
  admin: adminReducer,
  devices: deviceReducers,
  dashboards: dashboardReducer,
  reports: reportReducers,
  site: siteReducers,
  location: locationReducers,
});

//Clears state at logout
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT" || action.type ==="LOGIN_SUCCESS") {

    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// const rootReducer = (state, action) => {
//   if (action.type === 'LOGOUT') {
//     localStorage.removeItem("persist:root");
//     const { routing } = state
//     state = { routing } 
//   }
//   return appReducer(state, action)
// }

export default rootReducer;
