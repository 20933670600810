import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Spinner, Alert } from "reactstrap";
import { activateDeviceAction, getDevicesAction } from "../redux/deviceActions";
import { getUserOrganisationAction } from "../../user/redux/userActions";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

//Icons
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import MultiSelect from "../../../shared/components/multiSelect/multiSelect";

const AddNewDeviceModal = (props) => {
  //Local State
  const [modal, setModal] = useState(false);
  const [formError, setFormError] = useState(null);
  const [siteId, setSiteId] = useState(0);
  const [newLocationInput, setNewLocationInput] = useState(false);
  const [locationDisabled, setLocationDisabled] = useState(true);

  //Toggle modal visibility
  const toggle = () => {
    setModal((prevState) => !prevState);
    //Reset the form to default render
    setNewLocationInput(false);
    setLocationDisabled(true);
    setSiteId(0);
  };

  //Toggle site input visibility
  const toggleLocationInput = () => {
    setNewLocationInput((prevState) => !prevState);
  };

  const formValidation = Yup.object().shape({
    // device_id: Yup.string().required("Device EUI is required"),
    device_name: Yup.string().min(3, "Device name is too short. Must be 3 characters or more").required("Device name is required"),
    device_location: Yup.string().required("Location is required"),
    device_site: Yup.string().required("Site is required"),
  });

  return (
    <React.Fragment>
      <Button className="icon btn" color="success" onClick={toggle}>
        <p>
          <PlusCircleIcon />
          Add Device
        </p>
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog">
        <div className="card__title">
          <h5 className="bold-text">Add a new device to {props.organisation.name}</h5>
          <h5 className="subhead">Fill out the details below to add a new device. You can add more than one device, this pop up will stay open until you are finished.</h5>
        </div>
        {formError && <Alert color="danger">{formError}</Alert>}
        <Formik
          initialValues={{
            device_id: "",
            device_name: "",
            device_location: "",
            device_site: "",
          }}
          validationSchema={formValidation}
          onSubmit={async (values) => {
            console.log(values);
            values.device_id = values.device_id[0].value;
            props
              .activateDeviceAction(values)
              .then(() => {
                //If the device was successfully added update the device list
                props
                  .getDevicesAction()
                  .then(() => {
                    //If a new location was added fetch new users organisation object
                    if (newLocationInput) {
                      props.getUserOrganisationAction().catch((error) => {
                        console.log(error);
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                setFormError(error.message);
              });
          }}
        >
          {({ handleChange, setFieldValue }) => (
            <Form>
              {formError && <Alert color="danger">{formError}</Alert>}
              <div className="form__form-group">
                <span className="form__form-group-label">Device EUI</span>
                <div className="form__form-group-field_multiSelect">
                  <MultiSelect name="device_id" options={props.inactiveDevices.map((device) => ({ value: device.id, label: device.eui }))} onChange={setFieldValue} />
                  {/* <Field name="device_id" as="select">
                    <option></option>
                    {props.inactiveDevices &&
                      props.inactiveDevices.map((device) => (
                        <option key={device.id} value={device.id}>
                          {device.eui}
                        </option>
                      ))}
                  </Field> */}
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="device_id" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Device Name</span>
                <div className="form__form-group-field">
                  <Field name="device_name" type="text" />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="device_name" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Site</span>
                <div className="form__form-group-field">
                  <Field
                    name="device_site"
                    as="select"
                    onChange={(e) => {
                      //Default Formik handler
                      handleChange(e);
                      setSiteId(e.target.value);

                      //Active the location input
                      setLocationDisabled(false);
                    }}
                  >
                    <option></option>
                    {props.sites &&
                      props.sites.map((site) => (
                        <option key={site.site.id} value={site.site.id}>
                          {site.site.name}
                        </option>
                      ))}
                  </Field>
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="device_site" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Device Location</span>
                {newLocationInput ? (
                  <React.Fragment>
                    <div className="form__form-group-field">
                      <Field name="device_location" type="text" placeholder="Enter new location... eg. Meeting Room 1" disabled={locationDisabled} />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="form__form-group-field">
                      <Field name="device_location" as="select" disabled={locationDisabled}>
                        <option></option>
                        {props.sites &&
                          props.sites
                            .filter((site) => (siteId == 0 ? true : site.site.id == siteId))
                            .map(
                              (site) =>
                                site.site.locations.length != 0 &&
                                site.site.locations.map((location) => (
                                  <option key={location.id} value={location.id}>
                                    {location.name}
                                  </option>
                                ))
                            )}
                      </Field>
                    </div>
                  </React.Fragment>
                )}
                {!locationDisabled && (
                  <div className="location-input-btn" onClick={toggleLocationInput}>
                    Add new location
                  </div>
                )}
                <div className="form__error_message">
                  <ErrorMessage name="device_location" />
                </div>
              </div>
              <Button className="icon" color="success" type="submit">
                {props.isFetching ? (
                  <Spinner animation="border" className="btn-loading-spinner" />
                ) : (
                  <p>
                    <ContentSaveIcon /> Add Device
                  </p>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getDevicesAction: getDevicesAction,
  activateDeviceAction: activateDeviceAction,
  getUserOrganisationAction: getUserOrganisationAction,
};

const mapStateToProps = (state) => ({
  isFetching: state.devices.isFetching,
  organisation: state.user.organisation,
  inactiveDevices: state.devices.inactiveDevices,
  sites: state.user.sites,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewDeviceModal));
