import React from "react";
import { Card, CardBody } from "reactstrap";
import DataTable from "../../../shared/components/table/dataTable";

const DeviceMeasurementsTable = ({ tableCardData }) => {
  const tableHeaders = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      type: "dateTime",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "activity_level",
      headerName: "Activity Level",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "CO2",
      headerName: "CO2",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${params.value} ppm`;
      },
    },
    {
      field: "humidity",
      headerName: "Humidity",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: "illumination",
      headerName: "Illumination",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${params.value} lux`;
      },
    },
    {
      field: "infrared",
      headerName: "Infrared ",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${params.value} lux`;
      },
    },
    {
      field: "pressure",
      headerName: "Pressure",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${Number(params.value).toFixed(1)} Pa`;
      },
    },
    {
      field: "TVOC",
      headerName: "TVOC",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${params.value} ppb`;
      },
    },
    {
      field: "temperature",
      headerName: "Temperature",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return `${Number(params.value).toFixed(1)}°C`;
      },
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((value, index) => {
        array.push({
          id: index,
          timestamp: value.timestamp,
          CO2: value.CO2,
          activity_level: value.activity_level,
          humidity: value.humidity,
          illumination: value.illumination,
          infrared: value.infrared,
          pressure: value.pressure,
          temperature: value.temperature,
          activity_level: value.activity_level,
          TVOC: value.TVOC,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} tableSize={5} disableFiltering={false} hideFooter={true} showToolbar={false} />;
};

export default DeviceMeasurementsTable;
