import React from "react";
import { Col, Container, Row } from "reactstrap";
import AddNewUser from "../../containers/admin/addNewUser";

const AddNewUserView = () => (
  <Container className="dashboard">
    <Row>
      <Col lg="12" md="12" sm="12">
        <h3 className="page-title">Platform Administration</h3>
        <h3 className="page-subhead subhead">ROLE: SUPER_ADMIN</h3>
      </Col>
    </Row>
    <Row>
      <AddNewUser />
    </Row>
  </Container>
);

export default AddNewUserView;
