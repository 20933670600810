import React from "react";
import { useField } from "formik";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const DatePicker = (props) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [field, meta] = useField(props);

  const handleChange = (item) => {
    props.onChange(field.name, item.selection);
  };

  return (
    <DateRange
      editableDateInputs={true}
      onChange={(item) => {
        setState([item.selection]);
        handleChange(item);
      }}
      moveRangeOnFirstSelection={false}
      ranges={state}
      maxDate={new Date()}
    />
  );
};

export default DatePicker;
