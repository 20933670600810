import React from "react";
import { Col, Card, CardBody, Spinner, Row } from "reactstrap";
import { connect } from "react-redux";
import { getOrganisationsAction, getUsersAction } from "../redux/adminActions";
import UserTable from "./components/userTable";
import AddNewUserModel from "./components/addNewUserModel";

class Users extends React.Component {
  componentDidMount() {
    this.props.getUsersAction();
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <AddNewUserModel />
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Card>
              <CardBody>{this.props.isFetching ? <Spinner /> : this.props.users ? <UserTable tableCardData={this.props.users} /> : "Error loading data"}</CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  getOrganisationsAction: getOrganisationsAction,
  getUsersAction: getUsersAction,
};

const mapStateToProps = (state) => ({
  isFetching: state.admin.isFetching,
  users: state.admin.users,
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
