import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

//
//
//
//Get site averages
export const getSiteAveragesRequest = createAction("GET_SITE_AVERAGES_REQUEST");
export const getSiteAveragesSuccess = createAction("GET_SITE_AVERAGES_SUCCESS");
export const getSiteAveragesFailure = createAction("GET_SITE_AVERAGES_FAILURE");

export const getSiteAveragesAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getSiteAveragesRequest());
    const response = await api.getSiteAverages.get(values);
    dispatch(getSiteAveragesSuccess(response.data));
  } catch (error) {
    dispatch(getSiteAveragesFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get site locations
export const getSiteLocationsRequest = createAction("GET_SITE_LOCATIONS_REQUEST");
export const getSiteLocationsSuccess = createAction("GET_SITE_LOCATIONS_SUCCESS");
export const getSiteLocationsFailure = createAction("GET_SITE_LOCATIONS_FAILURE");

export const getSiteLocationsAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getSiteLocationsRequest());
    const response = await api.getSiteLocations.get(values);
    dispatch(getSiteLocationsSuccess(response.data));
  } catch (error) {
    dispatch(getSiteLocationsFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get site locations
export const getSiteLocationsPerformanceRequest = createAction("GET_SITE_LOCATIONS_PERFORMANCE_REQUEST");
export const getSiteLocationsPerformanceSuccess = createAction("GET_SITE_LOCATIONS_PERFORMANCE_SUCCESS");
export const getSiteLocationsPerformanceFailure = createAction("GET_SITE_LOCATIONS_PERFORMANCE_FAILURE");

export const getSiteLocationsPerformanceAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getSiteLocationsPerformanceRequest());
    const response = await api.getSiteLocationPerformance.get(values);
    dispatch(getSiteLocationsPerformanceSuccess(response.data));
  } catch (error) {
    dispatch(getSiteLocationsPerformanceFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get site locations
export const getAllSitesRequest = createAction("GET_ALL_SITES_REQUEST");
export const getAllSitesSuccess = createAction("GET_ALL_SITES_SUCCESS");
export const getAllSitesFailure = createAction("GET_ALL_SITES_FAILURE");

export const getAllSitesAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getAllSitesRequest());
    const response = await api.getAllSites.get(values);
    dispatch(getAllSitesSuccess(response.data));
  } catch (error) {
    dispatch(getAllSitesFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};
