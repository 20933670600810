import React, { PureComponent } from "react";
import { Modal, Button, Spinner, Alert } from "reactstrap";
import { connect } from "react-redux";
import { enrollUserAction, getOrganisationsAction, getRolesAction } from "../../redux/adminActions";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import ToastifyNotification from "../../../../shared/components/notification/index";

//Icons
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import MultiSelect from "../../../../shared/components/multiSelect/multiSelect";

class AddNewUserForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      formError: null,
      allSites: false,
    };
  }

  componentDidMount() {
    this.props.getRolesAction();
    this.props.getOrganisationsAction();
  }

  formValidation = () =>
    Yup.object().shape({
      organisation: Yup.string().required("Organisation is required"),
      role: Yup.string().required("User role is required"),
      email: Yup.string().email("Please enter a valid email").required("Email is required"),
      firstname: Yup.string().min(3, "First name to short. Must be 3 characters or more").required("First name is required"),
      surname: Yup.string().min(3, "Last name to short. Must be 3 characters or more").required("Last name is required"),
    });

  toggleAllSitesOptions = (e, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue("sites", [{ allSites: e.target.checked }]);
    } else {
      setFieldValue("sites", "");
    }
    this.setState({
      allSites: !this.state.allSites,
    });
  };

  render() {
    const sites = this.props.sites.map((site) => ({ value: site.site.id, label: site.site.name }));
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            role: "",
            email: "",
            firstname: "",
            surname: "",
            organisation: this.props.user.organisation.id,
            url: window.location.origin,
            sites: "",
            allSites: false,
          }}
          validationSchema={this.formValidation}
          onSubmit={async (values, { resetForm }) => {
            this.props
              .enrollUserAction(values)
              .then(() => {
                ToastifyNotification("User enrolled. The user will now receive a email to complete the account set up.");
                this.props.getOrganisationsAction().then(() => {
                  resetForm();
                });
              })
              .catch((error) => {
                this.setState({
                  formError: error.message,
                });
              });
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <Field name="email" type="email" />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="email" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <Field name="firstname" type="text" />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="firstname" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Last Name</span>
                <div className="form__form-group-field">
                  <Field name="surname" type="text" />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="surname" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">User Role</span>
                <div className="form__form-group-field">
                  <Field name="role" as="select">
                    <option></option>
                    {this.props.user.role.id === 1
                      ? this.props.roles &&
                        this.props.roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.role}
                          </option>
                        ))
                      : this.props.roles &&
                        this.props.roles
                          .filter((role) => role.id !== 1)
                          .map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.role}
                            </option>
                          ))}
                  </Field>
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="role" />
                </div>
              </div>
              <div className="form__form-group">
                <p className="form__form-group-label">Select the sites the user should have access to</p>
                <div style={{ width: "50%", margin: "inherit" }} className="form__form-group-field_multiSelect">
                  <MultiSelect name="sites" options={sites} onChange={setFieldValue} disabled={this.state.allSites} />
                </div>
                <div className="form__form-group_checkbox">
                  <span>Include all sites?</span>
                  <Field
                    className="checkbox"
                    type="checkbox"
                    name="allSites"
                    onClick={(e) => {
                      this.toggleAllSitesOptions(e, setFieldValue);
                    }}
                  />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="sites" />
                </div>
              </div>
              <Button className="icon" color="success" type="submit">
                {this.props.isFetching ? (
                  <Spinner animation="border" className="btn-loading-spinner" />
                ) : (
                  <p>
                    <ContentSaveIcon /> Add User
                  </p>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  roles: state.admin.roleList,
  organisations: state.admin.organisationList,
  isFetching: state.admin.isFetching,
  sites: state.user.sites,
});

const mapDispatchToProps = {
  enrollUserAction: enrollUserAction,
  getRolesAction: getRolesAction,
  getOrganisationsAction: getOrganisationsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserForm);
