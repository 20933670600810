import React from "react";
import { Col, Container, Row } from "reactstrap";
import LatestMeasurements from "../containers/dashboards/latestMeasurements";

const LatestMeasurementsDashboardView = () => (
  <Container className="dashboard">
    <Row>
      <Col lg="12" md="12" sm="12">
        <h3 className="page-title">Current environment measurements from all devices</h3>
        <h3 className="page-subhead subhead">View and filter all the latest measurements from each device.</h3>
      </Col>
    </Row>
    <LatestMeasurements />
  </Container>
);

export default LatestMeasurementsDashboardView;
