import { login, register, resetPassword, getUsersOrganisation, deleteUser, userActivation, userRequestPasswordReset, userPasswordResetViaToken } from "./api/user";
import { getDevice, getDevices, activateDevice, updateDevice, getDeviceMeasurements, getOrgLocations, getLatestDeviceMeasurements, getSiteDevices } from "./api/devices";
import {
  getOrganisations,
  getOrganisation,
  registerOrganisation,
  getRoles,
  enrollDevice,
  getDeviceModels,
  createSite,
  getUserSitePermissions,
  updateUserSitePermissions,
  enrollUser,
  updateUser,
  getUsers,
} from "./api/admin";
import { getAggregatedData24hrs } from "./api/dashboard";
import { createReport, getReport, runReport, getAllReportRuns, getReportRun, downloadReport, deleteReport, deleteReportRun } from "./api/report";
import { createLocation, updateLocation, getLocation, getLocationDevices, getAllLocations } from "./api/location";
import { getAllSites, getSiteAverages, getSiteLocationPerformance, getSiteLocations } from "./api/site";

const api = {
  login: login,
  register: register,
  resetPassword: resetPassword,
  getDevice: getDevice,
  getDevices: getDevices,
  getOrganisations: getOrganisations,
  getRoles: getRoles,
  getDeviceModels: getDeviceModels,
  activateDevice: activateDevice,
  updateDevice: updateDevice,
  getDeviceMeasurements: getDeviceMeasurements,
  getOrgLocations: getOrgLocations,
  getLatestDeviceMeasurements: getLatestDeviceMeasurements,
  getAggregatedData24hrs: getAggregatedData24hrs,
  createReport: createReport,
  getReport: getReport,
  runReport: runReport,
  getAllReportRuns: getAllReportRuns,
  getReportRun: getReportRun,
  downloadReport: downloadReport,
  deleteReport: deleteReport,
  deleteReportRun: deleteReportRun,
  getUsersOrganisation: getUsersOrganisation,
  getOrganisation: getOrganisation,
  getSiteDevices: getSiteDevices,
  createSite: createSite,
  getUserSitePermissions: getUserSitePermissions,
  updateUserSitePermissions: updateUserSitePermissions,
  createLocation: createLocation,
  updateLocation: updateLocation,
  getLocation: getLocation,
  deleteUser: deleteUser,
  getSiteAverages: getSiteAverages,
  getSiteLocations: getSiteLocations,
  getLocationDevices: getLocationDevices,
  getSiteLocationPerformance: getSiteLocationPerformance,
  getAllLocations: getAllLocations,
  getAllSites: getAllSites,
  userActivation: userActivation,
  enrollUser: enrollUser,
  registerOrganisation: registerOrganisation,
  enrollDevice: enrollDevice,
  userRequestPasswordReset: userRequestPasswordReset,
  userPasswordResetViaToken: userPasswordResetViaToken,
  updateUser: updateUser,
  getUsers: getUsers,
};

export default api;
