export const API_ROOT = process.env.REACT_APP_ENV;

export const environment_variable_meta = {
  CO2: {
    unit: "ppm",
    threshold: 1000,
  },
  Temperature: {
    unit: "°C",
    threshold: 25,
  },
  TVOC: {
    unit: "ppb",
    threshold: 2000,
  },
  Humidity: {
    unit: "%",
    threshold: 70,
  },
  "Activity Level": {
    unit: "Triggers",
    threshold: 1500,
  },
  Illumination: {
    unit: "LUX",
    threshold: 300,
  },
};
