import React from "react";
import { Col, Container, Row } from "reactstrap";
import Locations from "../containers/locations";

const LocationsPage = (props) => {
  return (
    <Container className="dashboard">
      <Row>
        <Col lg="12" md="12" sm="12">
          <h3 className="page-title">Locations</h3>
          <h3 className="page-subhead subhead">Below is a overview of all locations at each site</h3>
        </Col>
      </Row>
      <Locations />
    </Container>
  );
};

export default LocationsPage;
