import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

//Latest Measurements for all devices
export const latestMeasurementsAllDevicesRequest = createAction("LATEST_MEASUREMENTS_REQUEST");
export const latestMeasurementsAllDevicesSuccess = createAction("LATEST_MEASUREMENTS_SUCCESS");
export const latestMeasurementsAllDevicesFailure = createAction("LATEST_MEASUREMENTS_FAILURE");

export const getLatestMeasurementsAllDevicesAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(latestMeasurementsAllDevicesRequest());
    const response = await api.getLatestDeviceMeasurements.get(values);
    dispatch(latestMeasurementsAllDevicesSuccess({ measurements: response.data }));
  } catch (error) {
    dispatch(latestMeasurementsAllDevicesFailure(error.response.data.error));
    throw new Error(`Error ${error.response.status}: ${error.response.data.error}`);
  }
};

//Aggregated 24hr average of measurements per device
export const aggregatedData24hrsRequest = createAction("AGGREGATED_24HRS_DATA_REQUEST");
export const aggregatedData24hrsSuccess = createAction("AGGREGATED_24HRS_DATA_SUCCESS");
export const aggregatedData24hrsFailure = createAction("AGGREGATED_24HRS_DATA_FAILURE");

export const getAggregatedData24hrsAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(aggregatedData24hrsRequest());
    const response = await api.getAggregatedData24hrs.get(values);
    dispatch(aggregatedData24hrsSuccess({ measurements: response.data }));
  } catch (error) {
    dispatch(aggregatedData24hrsFailure(error.response.data.error));
    throw new Error(`Error ${error.response.status}: ${error.response.data.error}`);
  }
};
