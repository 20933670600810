import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

//Heatmap colors
const colors = [
  "#1d9a00",
  "#209a00",
  "#239b00",
  "#269b01",
  "#299c01",
  "#2c9c01",
  "#309d01",
  "#339d02",
  "#369e02",
  "#399e02",
  "#3c9e02",
  "#3f9f02",
  "#439f03",
  "#46a003",
  "#49a003",
  "#4ca103",
  "#4fa104",
  "#53a204",
  "#56a204",
  "#59a204",
  "#5ca304",
  "#60a305",
  "#63a405",
  "#66a405",
  "#69a505",
  "#6da506",
  "#70a606",
  "#73a606",
  "#77a606",
  "#7aa707",
  "#7da707",
  "#81a807",
  "#84a807",
  "#87a908",
  "#8ba908",
  "#8eaa08",
  "#91aa08",
  "#95aa09",
  "#98ab09",
  "#9bab09",
  "#9fac09",
  "#a2ac0a",
  "#a6ad0a",
  "#a9ad0a",
  "#acae0b",
  "#aeac0b",
  "#aeaa0b",
  "#afa70b",
  "#afa40c",
  "#b0a20c",
  "#b09f0c",
  "#b19d0c",
  "#b19a0d",
  "#b2970d",
  "#b2950d",
  "#b2920e",
  "#b3900e",
  "#b38d0e",
  "#b48a0e",
  "#b4880f",
  "#b5850f",
  "#b5830f",
  "#b68010",
  "#b67d10",
  "#b67b10",
  "#b77810",
  "#b77511",
  "#b87311",
  "#b87011",
  "#b96d12",
  "#b96b12",
  "#ba6812",
  "#ba6512",
  "#ba6313",
  "#bb6013",
  "#bb5d13",
  "#bc5a14",
  "#bc5814",
  "#bd5514",
  "#bd5215",
  "#be5015",
  "#be4d15",
  "#be4a16",
  "#bf4716",
  "#bf4516",
  "#c04216",
  "#c03f17",
  "#c13c17",
  "#c13a17",
  "#c23718",
  "#c23418",
  "#c23118",
  "#c32f19",
  "#c32c19",
  "#c42919",
  "#c4261a",
  "#c5231a",
  "#c5211a",
  "#c61e1b",
];

const HeatmapGraph = (props) => {
  //Generate color ranges for heatmap
  const colorRangeGenerator = (colors, parts, duration) => {
    var sections = duration / parts;
    var intervals = [];
    for (var i = 0; i < parts; i++) {
      intervals.push({
        from: i * sections,
        to: (i + 1) * sections,
        color: colors[i],
      });
    }
    return intervals;
  };

  //Chart options
  var chartOptions = {
    series: props.chartData,
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    noData: {
      text: "No data text",
      align: "center",
      verticalAlign: "middle",
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      show: true,
      borderColor: "#eff1f5",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: colorRangeGenerator(colors, 98, props.environmentVariableThreshold),
        },
      },
    },
    colors: ["#c61b1b"],
    tooltip: {
      x: {
        show: true,
        format: "dd-MM-yyyy HH:mm",
      },
    },
    xaxis: {
      type: "datetime",
    },
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
    },
  };

  return props.chartData.length > 0 ? (
    <Chart
      options={chartOptions}
      series={chartOptions.series}
      type="heatmap"
      height={props.chartData.length === 1 ? 100 : props.chartData.length * 50}
      style={{ minWidth: "1000px", padding: "10px" }}
    />
  ) : (
    "No data."
  );
};

HeatmapGraph.propTypes = {
  chartData: PropTypes.array.isRequired,
};

export default HeatmapGraph;
