import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Alert } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { getUserOrganisationAction } from "../../user/redux/userActions";

//Icons
import ContentSaveEditIcon from "mdi-react/PlusCircleIcon";
import CloseIcon from "mdi-react/ContentSaveIcon";
import PencilIcon from "mdi-react/PencilIcon";
import { getLocationAction, updateLocationAction } from "../../locations/redux/locationActions";

const LocationDetailsForm = (props) => {
  //Local State
  const [formError, setFormError] = useState(null);
  const [editable, setEditable] = useState(false);

  const formValidation = Yup.object().shape({
    name: Yup.string().required("Please enter a location name"),
  });

  return (
    <React.Fragment>
      {formError && <Alert color="danger">{formError}</Alert>}
      <Formik
        initialValues={{
          id: props.location[0].id,
          name: props.location[0].name,
          site_id: props.location[0].site_id,
        }}
        validationSchema={formValidation}
        onSubmit={async (values) => {
          props
            .updateLocationAction(values)
            .then(() => {
              //If the location was successfully update fetch the new update data
              props.getUserOrganisationAction().catch((error) => {
                console.log(error);
              });

              props.getLocationAction({ location_id: props.location[0].id }).catch((error) => {
                console.log(error);
              });

              setEditable(false);
            })
            .catch((error) => {
              setFormError(error.message);
            });
        }}
      >
        {({ handleChange, setErrors, resetForm }) => (
          <Form>
            <div className="form__form-group">
              <span className="form__form-group-label">Location Name</span>
              <div className="form__form-group-field">
                <Field name="name" type="text" disabled={!editable} />
              </div>
              <div className="form__error_message">
                <ErrorMessage name="name" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Site</span>
              <div className="form__form-group-field">
                <Field name="site_id" as="select" disabled={!editable}>
                  <option></option>
                  {props.sites &&
                    props.sites.map((site) => (
                      <option key={site.site.id} value={site.site.id}>
                        {site.site.name}
                      </option>
                    ))}
                </Field>
              </div>
              <div className="form__error_message">
                <ErrorMessage name="site_id" />
              </div>
            </div>
            {editable ? (
              <ButtonGroup>
                <Button className="icon" color="success" type="submit">
                  <p>
                    <ContentSaveEditIcon /> Save changes
                  </p>
                </Button>
                <Button
                  className="icon"
                  color="danger"
                  onClick={() => {
                    setEditable((editable) => !editable);

                    //Call default Formik handlers
                    setErrors({});
                    resetForm();
                  }}
                >
                  <p>
                    <CloseIcon /> Cancel
                  </p>
                </Button>
              </ButtonGroup>
            ) : (
              <Button className="icon" color="primary" onClick={() => setEditable((editable) => !editable)}>
                <p>
                  <PencilIcon /> Edit
                </p>
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  updateLocationAction: updateLocationAction,
  getUserOrganisationAction: getUserOrganisationAction,
  getLocationAction: getLocationAction,
};

const mapStateToProps = (state) => ({
  sites: state.user.sites,
  location: state.location.location,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationDetailsForm));
