import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

export const logout = createAction("LOGOUT");

//
//
//
// USer login action
export const loginRequest = createAction("LOGIN_REQUEST");
export const loginSuccess = createAction("LOGIN_SUCCESS");
export const loginFailure = createAction("LOGIN_FAILURE");

export const loginAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(loginRequest());
    const response = await api.login.post(values);
    const loginData = {
      user: response.data.user,
      isAuthenticated: true,
    };
    // set new localStorage
    localStorage.setItem("access", response.data.access_token);
    localStorage.setItem("refresh", response.data.refresh_token);
    dispatch(loginSuccess(loginData));
  } catch (error) {
    dispatch(loginFailure(error.response.data));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
// User registration action
export const registerRequest = createAction("REGISTER_REQUEST");
export const registerSuccess = createAction("REGISTER_SUCCESS");
export const registerFailure = createAction("REGISTER_FAILURE");

export const registerAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(registerRequest());
    const response = await api.register.post(values);
    const registerData = {
      success: response.data.success,
    };
    dispatch(registerSuccess(registerData));
  } catch (error) {
    dispatch(registerFailure({ error }));
    throw error;
  }
};

//
//
//
//Reset password action
export const resetPasswordRequest = createAction("RESET_PASSWORD_REQUEST");
export const resetPasswordSuccess = createAction("RESET_PASSWORD_SUCCESS");
export const resetPasswordFailure = createAction("RESET_PASSWORD_FAILURE");

export const resetPasswordAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(resetPasswordRequest());
    const response = await api.resetPassword.post(values);
    dispatch(resetPasswordSuccess());
  } catch (error) {
    dispatch(resetPasswordFailure({ error }));
    throw error;
  }
};

//
//
//
// Get the user object containing all user information
export const getUserOrganisationRequest = createAction("GET_USERS_ORGANISATION_REQUEST");
export const getUserOrganisationSuccess = createAction("GET_USERS_ORGANISATION_SUCCESS");
export const getUserOrganisationFailure = createAction("GET_USERS_ORGANISATION_FAILURE");

export const getUserOrganisationAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getUserOrganisationRequest());
    const response = await api.getUsersOrganisation.get();
    dispatch(getUserOrganisationSuccess(response.data));
  } catch (error) {
    dispatch(getUserOrganisationFailure({ error }));
    throw error;
  }
};

//
//
//
// Get the user object containing all user information
export const activateUserRequest = createAction("ACTIVATE_USER_REQUEST");
export const activateUserSuccess = createAction("ACTIVATE_USER_SUCCESS");
export const activateUserFailure = createAction("ACTIVATE_USER_FAILURE");

export const activateUserAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(activateUserRequest());
    const response = await api.userActivation.post(values);
    dispatch(activateUserSuccess(response.data));
  } catch (error) {
    dispatch(activateUserFailure(error.response.data));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
// Reset password request. Send password reset email to user
export const requestPasswordResetRequest = createAction("REQUEST_PASSWORD_RESET_REQUEST");
export const requestPasswordResetSuccess = createAction("REQUEST_PASSWORD_RESET_SUCCESS");
export const requestPasswordResetFailure = createAction("REQUEST_PASSWORD_RESET_FAILURE");

export const requestPasswordResetAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(requestPasswordResetRequest());
    const response = await api.userRequestPasswordReset.post(values);
    dispatch(requestPasswordResetSuccess(response.data));
  } catch (error) {
    dispatch(requestPasswordResetFailure(error.response.data));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
// Reset password via token
export const passwordResetViaTokenRequest = createAction("PASSWORD_RESET_VIA_TOKEN_REQUEST");
export const passwordResetViaTokenSuccess = createAction("PASSWORD_RESET_VIA_TOKEN_SUCCESS");
export const passwordResetViaTokenFailure = createAction("PASSWORD_RESET_VIA_TOKEN_FAILURE");

export const passwordResetViaTokenAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(passwordResetViaTokenRequest());
    const response = await api.userPasswordResetViaToken.post(values);
    dispatch(passwordResetViaTokenSuccess(response.data));
  } catch (error) {
    dispatch(passwordResetViaTokenFailure(error.response.data));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};
