import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import SensorLineChart from "./components/sensorLineChart";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceMeasurementsAction } from "../devices/redux/deviceActions";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";

const DevicesDashboard = (props) => {
  const dispatch = useDispatch();
  const measurements = useSelector((state) => state.devices.measurements);
  const isFetching = useSelector((state) => state.devices.isFetching);
  const devices = useSelector((state) => state.devices.activeDevices);

  const device = devices.filter((device) => device.id == props.device_id)[0];

  useEffect(() => {
    dispatch(getDeviceMeasurementsAction(props.device_id));
  }, [dispatch, props.device_id]);

  return (
    <React.Fragment>
      <Row>
        <Col lg="12" md="12" sm="12">
          <h3 className="page-title">{device.name}</h3>
          <h3 className="page-subhead subhead">New data collected every 10 minutes</h3>
        </Col>
      </Row>
      {isFetching ? (
        <LoadingSpinner loadingText="Loading device Data..." />
      ) : !isFetching && measurements && Object.keys(measurements) != 0 ? (
        <React.Fragment>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="Co2"
                chartData={measurements.CO2}
                toolTipString="Displayed is the Co2 that the device has collected over the previous 24hrs. CO2 displayed in PPM"
                cardId="lineChartCo2"
                chartColors={["#0e95bd"]}
                unit="ppm"
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="TVOC"
                chartData={measurements.TVOC}
                toolTipString="Displayed is the TVOC that the device has collected over the previous 24hrs. TVOC displayed in PPB"
                cardId="lineChartTvoc"
                chartColors={["#0e95bd"]}
                unit="ppb"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="Temperature"
                chartData={measurements.Temperature}
                toolTipString="Displayed is the temperature that the device has collected over the previous 24hrs. Temperature displayed in °C"
                cardId="lineChartTemp"
                chartColors={["#0e95bd"]}
                unit="°C"
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="Humidity"
                chartData={measurements.Humidity}
                toolTipString="Displayed is the humidity that the device has collected over the previous 24hrs. Humidity displayed in %"
                cardId="lineChartHum"
                chartColors={["#0e95bd"]}
                unit="%"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="Pressure"
                chartData={measurements["Barometric Pressure"]}
                toolTipString="Displayed is the pressure that the device has collected over the previous 24hrs. Pressure displayed in Pa"
                cardId="lineChartPress"
                chartColors={["#0e95bd"]}
                unit="pa"
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="Illumination"
                chartData={measurements.Illumination}
                toolTipString="Displayed is the illumination that the device has collected over the previous 24hrs. Illumination displayed in lux"
                cardId="lineChartIllum"
                chartColors={["#0e95bd"]}
                unit="lux"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <SensorLineChart
                chartTitle="Activity Level"
                chartData={measurements["Activity Level"]}
                toolTipString="Displayed is the activity level that the device has collected over the previous 24hrs. Activity level displayed in triggers"
                cardId="lineChartIllum"
                chartColors={["#0e95bd"]}
                unit="triggers"
              />
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>No measurements found</p>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DevicesDashboard;
