import React, { PureComponent } from "react";
import { Button, Spinner, Alert } from "reactstrap";
import { connect } from "react-redux";
import { registerOrganisationAction } from "../../redux/adminActions";
import { withRouter } from "react-router-dom";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import ToastifyNotification from "../../../../shared/components/notification/index";
import { UncontrolledTooltip } from "reactstrap";
import HelpIcon from "mdi-react/HelpIcon";

//Icons
import ContentSaveIcon from "mdi-react/ContentSaveIcon";

class OrganisationForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formError: null,
    };
  }

  formValidation = () =>
    Yup.object().shape({
      name: Yup.string().min(3, "Organisation name must be 3 characters or more").required("Organisation name is required."),
      user_limit: Yup.number().min(1, "User limit must be at least 1").required("Please set a user limit."),
      device_limit: Yup.number().min(1, "Device limit must be at least 1").required("Please ser a device limit"),
      admin_email: Yup.string().email("Please enter a valid email").required("Email is required"),
      admin_password: Yup.string()
        .required("Password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    });

  render() {
    return (
      <Formik
        initialValues={{
          name: "",
          user_limit: "",
          device_limit: "",
          admin_email: "",
          admin_password: "",
        }}
        validationSchema={this.formValidation}
        onSubmit={async (values, { resetForm }) => {
          this.props
            .registerOrganisationAction(values)
            .then((res) => {
              ToastifyNotification("Organisation successfully added!");

              //Reset the form and remove any errors
              resetForm({});
              this.setState({
                formError: null,
              });
            })
            .catch((error) => {
              this.setState({
                formError: error.message,
              });
            });
        }}
      >
        <Form>
          {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
          <div className="form__form-group">
            <span className="form__form-group-label">Organisation Name</span>
            <div className="form__form-group-field">
              <Field name="name" type="text" />
            </div>
            <div className="form__error_message">
              <ErrorMessage name="name" />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">User Limit</span>
            <div className="form__form-group-field">
              <Field name="user_limit" type="number" />
            </div>
            <div className="form__error_message">
              <ErrorMessage name="user_limit" />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Device Limit</span>
            <div className="form__form-group-field">
              <Field name="device_limit" type="number" />
            </div>
            <div className="form__error_message">
              <ErrorMessage name="device_limit" />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__info_tooltip">
              <span className="form__form-group-label">MTG Admin Email</span>
              <HelpIcon id="mtgAdminEmail" />
              <UncontrolledTooltip placement="bottom" target="mtgAdminEmail">
                This is the admin account for MTG access only. Follow the format mtg_admin_xxx@mtg.im where xxx is the new org
              </UncontrolledTooltip>
            </div>
            <div className="form__form-group-field">
              <Field name="admin_email" type="email" />
            </div>
            <div className="form__error_message">
              <ErrorMessage name="admin_email" />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">MTG Admin Password</span>
            <div className="form__form-group-field">
              <Field name="admin_password" type="password" />
            </div>
            <div className="form__error_message">
              <ErrorMessage name="admin_password" />
            </div>
          </div>
          <Button className="icon" color="success" type="submit">
            {this.props.isFetching ? (
              <Spinner animation="border" className="btn-loading-spinner" />
            ) : (
              <p>
                <ContentSaveIcon /> Add
              </p>
            )}
          </Button>
        </Form>
      </Formik>
    );
  }
}

const mapDispatchToProps = {
  registerOrganisationAction: registerOrganisationAction,
};

const mapStateToProps = (state) => ({
  isFetching: state.admin.isFetching,
});

export default withRouter(connect(null, mapDispatchToProps)(OrganisationForm));
