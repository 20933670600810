import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Spinner, Alert } from "reactstrap";
import { Form, Formik, ErrorMessage, Field } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import ToastifyNotification from "../../../shared/components/notification";
import Select from "react-select";
import MultiSelect from "../../../shared/components/multiSelect/multiSelect";
import { createReportAction, getReportAction } from "../redux/reportActions";

//Icons
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";

const measurements_names = [
  {
    label: "CO2",
    value: 1,
  },
  {
    label: "Temperature",
    value: 2,
  },
  {
    label: "TVOC",
    value: 3,
  },
  {
    label: "Humidity",
    value: 4,
  },
  {
    label: "Activity Level",
    value: 5,
  },
  {
    label: "Illumination",
    value: 6,
  },
];

class AddNewReportModel extends React.Component {
  constructor() {
    super();
    this.state = {
      model: false,
      formError: null,
      deviceDropdown: true,
      allDeviceCheckbox: true,
      measurementsDropdown: true,
      selectedSite: 0,
    };
  }

  formValidation = () =>
    Yup.object().shape({
      reportName: Yup.string().min(3, "Must be more than 2 characters").required("Please enter a name for the report"),
      site: Yup.string().required("Please select a site"),
      devices: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
          })
        )
        .min(1, "Please select device(s) to include")
        .required("Please select device(s) to include"),
      measurements: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
          })
        )
        .min(1, "Please select a measurement(s) to include")
        .required("Please select a measurement(s) to include"),
    });

  toggleModal = () => {
    this.setState((prevState) => ({
      model: !prevState.model,
      deviceInputDisabled: true,
      allDeviceCheckbox: true,
      measurementsDropdown: true,
    }));
  };

  toggleAllDevicesOptions = (e, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue("devices", [{ all: e.target.checked }]);
    } else {
      setFieldValue("devices", "");
    }
    this.setState({
      deviceInputDisabled: !this.state.deviceInputDisabled,
    });
  };

  render() {
    //Sites dropdown
    var sitesOptions = [];
    this.props.sites.map((site) => sitesOptions.push({ value: site.site.id, label: site.site.name }));

    return (
      <React.Fragment>
        <Button className="icon btn" color="success" onClick={this.toggleModal}>
          <p>
            <PlusCircleIcon />
            Create new report
          </p>
        </Button>
        <Modal isOpen={this.state.model} toggle={this.toggleModal} className="modal-dialog">
          <React.Fragment>
            <div className="card__title">
              <h5 className="bold-text">Create a new report for {this.props.organisation.name}</h5>
              <h5 className="subhead">Fill out the details below to create a new report.</h5>
            </div>
            {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
            <Formik
              initialValues={{
                reportName: "",
                devices: "",
                measurements: "",
                allDevices: false,
                site: "",
              }}
              validationSchema={this.formValidation}
              onSubmit={async (values) => {
                await this.props
                  .createReportAction(values)
                  .then(() => {
                    this.props.getReportAction();
                    this.setState({
                      formError: null,
                    });
                    this.toggleModal();
                    ToastifyNotification("Report has been created");
                  })
                  .catch((error) => {
                    this.setState({
                      formError: error.message,
                    });
                  });
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="form__form-group">
                    <p className="form__form-group-label">Enter a name for the report</p>
                    <div className="form__form-group-field_multiSelect">
                      <Field type="text" name="reportName" />
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="reportName" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <p className="form__form-group-label">Select the site to include</p>
                    <div className="form__form-group-field_multiSelect">
                      <Select
                        name="site"
                        options={sitesOptions}
                        onChange={(e) => {
                          setFieldValue("site", e.value);
                          this.setState({ selectedSite: e.value, deviceInputDisabled: false, allDeviceCheckbox: false, measurementsDropdown: false });
                        }}
                      />
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="site" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <p className="form__form-group-label">Select the devices to include</p>
                    <div className="form__form-group-field_multiSelect">
                      <MultiSelect
                        name="devices"
                        options={this.props.devices.filter((device) => device.site.id == this.state.selectedSite).map((device) => ({ value: device.id, label: device.name }))}
                        onChange={setFieldValue}
                        disabled={this.state.deviceInputDisabled}
                      />
                    </div>
                    <div className="form__form-group_checkbox">
                      <span>Include all devices?</span>
                      <Field
                        className="checkbox"
                        type="checkbox"
                        name="allDevices"
                        onClick={(e) => {
                          this.toggleAllDevicesOptions(e, setFieldValue);
                        }}
                        disabled={this.state.allDeviceCheckbox}
                      />
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="devices" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <p className="form__form-group-label">Select the measurements to include</p>
                    <div className="form__form-group-field_multiSelect">
                      <MultiSelect name="measurements" options={measurements_names} onChange={setFieldValue} disabled={this.state.measurementsDropdown} />
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="measurements" />
                    </div>
                  </div>
                  <Button className="icon" color="success" type="submit">
                    {this.props.isFetching ? (
                      <Spinner animation="border" className="btn-loading-spinner" />
                    ) : (
                      <p>
                        <ContentSaveIcon /> Build report
                      </p>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  createReportAction: createReportAction,
  getReportAction: getReportAction,
};

const mapStateToProps = (state) => ({
  isFetching: false,
  organisation: state.user.organisation,
  devices: state.devices.activeDevices,
  sites: state.user.sites,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewReportModel));
