import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        series: this.props.chartData,
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        colors: this.props.chartColors,
        stroke: {
          width: 3,
          curve: "straight",
        },
        grid: {
          show: true,
          borderColor: "#eff1f5",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          x: {
            show: true,
            format: "MMM-dd HH:mm",
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        },
      },
    };
  }

  static propTypes = {
    chartData: PropTypes.array.isRequired,
    chartColors: PropTypes.arrayOf(PropTypes.string),
  };

  render() {
    return <Chart options={this.state.options} series={this.state.options.series} type="line" height={300} />;
  }
}

export default LineChart;
