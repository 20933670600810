import React from "react";
import { Row, Col, Card, CardBody, Spinner, Collapse } from "reactstrap";
import LatestMeasurementsTable from "./components/currentMeasurementsTable";
import { connect } from "react-redux";
import { getLatestMeasurementsAllDevicesAction } from "../redux/dashboardActions";
import FilterBar from "../../../shared/components/filterBar";

//icons
import MenuDownIcon from "mdi-react/MenuDownIcon";

class LatestMeasurements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        siteName: this.props.userSites[0] ? this.props.userSites[0].site.name : "-- All --",
        siteId: this.props.userSites[0] ? this.props.userSites[0].site.id : 0,
      },
      mobileFilterMenu: false,
    };
  }

  componentDidMount() {
    this.props.getLatestAllDeviceMeasurements({ site_id: this.state.filters.siteId }).catch((err) => {
      console.log(err);
    });
  }

  filterBySite = (e) => {
    this.setState(
      {
        filters: { ...this.state.filters, siteName: e.currentTarget.textContent, siteId: e.target.value },
      },
      () => {
        this.props.getLatestAllDeviceMeasurements({ name: this.state.filters.measurement, site_id: this.state.filters.siteId }).catch((err) => {
          console.log(err);
        });
      }
    );
  };

  toggleMobileFilterMenu = () => {
    this.setState({
      mobileFilterMenu: !this.state.mobileFilterMenu,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="filter_bar mobile">
              <Card>
                <CardBody className="filter_bar__card_body">
                  <div className="filter_bar__mobile_filter_menu_toggle">
                    <span onClick={this.toggleMobileFilterMenu}>
                      Filter measurements
                      <MenuDownIcon />
                    </span>
                  </div>
                  <Collapse className="filter_bar__mobile_filter_menu" isOpen={this.state.mobileFilterMenu}>
                    <FilterBar filters={this.state.filters} filterDevicesBySiteFunc={this.filterBySite} />
                  </Collapse>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg="12" md="12" sm="12">
            <div className="filter_bar desktop">
              <Card>
                <CardBody className="filter_bar__card_body">
                  <FilterBar filters={this.state.filters} filterDevicesBySiteFunc={this.filterBySite} />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Latest telemetry</h5>
                  <h5 className="subhead">View the latest measurements from all the devices</h5>
                </div>
                {this.props.isFetching ? <Spinner /> : this.props.latest_measurements ? <LatestMeasurementsTable tableCardData={this.props.latest_measurements} /> : "No data"}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.dashboards.isFetching,
  latest_measurements: state.dashboards.latest_measurements,
  userSites: state.user.sites,
});

const mapDispatchToProps = {
  getLatestAllDeviceMeasurements: getLatestMeasurementsAllDevicesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestMeasurements);
