import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Spinner } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

//Icons
import EyeIcon from "mdi-react/EyeIcon";
import { activateUserAction } from "../../redux/userActions";

const UserActivationForm = (props) => {
  //Local state
  const [showPassword, setShowPassword] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  //Toggle password visibility
  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const formValidation = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password is too short. Must be 8 characters or more.")
      .required("Password is required")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Confirm password is required"),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
          token: props.match.params.token,
        }}
        validationSchema={formValidation}
        onSubmit={async (values) => {
          props
            .activateUserAction(values)
            .then(() => {
              setSuccessMessage("Account Activated.\n Please wait while you are redirected to login");
              setTimeout(() => {
                props.history.push("/login");
              }, 5000);
            })
            .catch((error) => {
              setFormErr(error.message);
            });
        }}
      >
        <Form>
          {successMessage && <Alert color="success">{successMessage}</Alert>}
          {formErr && <Alert color="danger">{formErr}</Alert>}
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Field name="password" type={showPassword ? "text" : "password"} autoComplete="off"/>
              <button className={`form__form-group-button${showPassword ? " active" : ""}`} onClick={(e) => togglePassword(e)} type="button">
                <EyeIcon />
              </button>
            </div>
            <div className="form__error_message">
              <ErrorMessage name="password" />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Confirm Password</span>
            <div className="form__form-group-field">
              <Field name="confirmPassword" type={showPassword ? "text" : "password"} autoComplete="off"/>
              <button className={`form__form-group-button${showPassword ? " active" : ""}`} onClick={(e) => togglePassword(e)} type="button">
                <EyeIcon />
              </button>
            </div>
            <div className="form__error_message">
              <ErrorMessage name="confirmPassword" />
            </div>
          </div>
          <button className="btn btn-primary login__login_btn" type="submit">
            {props.isFetching ? <Spinner animation="border" className="login__loading_spinner" /> : "Activate"}
          </button>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  activateUserAction: activateUserAction,
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserActivationForm));
