import React from "react";
import { connect } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, InputGroup, Input } from "reactstrap";

//Icons
import FilterVariantIcon from "mdi-react/FilterVariantIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

const FilterBar = (props) => {
  return (
    <div className="filter_bar__filters_wrapper">
      <FilterVariantIcon />
      {props.filterDevicesByMeasurementFunc && (
        <UncontrolledDropdown>
          <DropdownToggle className="icon icon--right filter_bar__filter_controls_btn" outline>
            <p>
              {props.filters.measurement} <ChevronDownIcon />
            </p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            {props.measurementHeaders &&
              props.measurementHeaders
              .sort((a, b) => a.localeCompare(b))
              .map((measurement, index) => (
                <DropdownItem key={"measurement" + index} value={measurement} onClick={props.filterDevicesByMeasurementFunc}>
                  {measurement}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {props.filterDevicesByNameFunc && (
        <InputGroup>
          <Input className="filter_bar__filter_controls_input" placeholder="Device name..." onChange={props.filterDevicesByNameFunc} />
        </InputGroup>
      )}
      {props.filterDevicesBySiteFunc && (
        <UncontrolledDropdown>
          <DropdownToggle className="icon icon--right filter_bar__filter_controls_btn" outline>
            <p>
              {props.filters.siteName} <ChevronDownIcon />
            </p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <DropdownItem value={0} onClick={props.filterDevicesBySiteFunc}>
            </DropdownItem>
            {props.userSites &&
              props.userSites
              .sort((a, b) => a.site.name.localeCompare(b.site.name))
              .map((site) => (
                <DropdownItem key={site.site.id} value={site.site.id} onClick={props.filterDevicesBySiteFunc}>
                  {site.site.name}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
      {props.filterDevicesByLocationFunc && (
        <UncontrolledDropdown>
          <DropdownToggle className="icon icon--right filter_bar__filter_controls_btn" outline>
            <p>
              {props.filters.locationName} <ChevronDownIcon />
            </p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu">
            <DropdownItem value={0} onClick={props.filterDevicesByLocationFunc}>
            </DropdownItem>
            {props.userSites &&
              props.userSites
                .filter((site) => (props.filters.siteId == 0 ? true : site.site.id == props.filters.siteId))
                .map(
                  (site) =>
                    site.site.locations.length != 0 &&
                    site.site.locations.map((location) => (
                      <DropdownItem key={location.id} value={location.id} onClick={props.filterDevicesByLocationFunc}>
                        {location.name}
                      </DropdownItem>
                    ))
                )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userSites: state.user.sites,
});

export default connect(mapStateToProps, null)(FilterBar);
