import { API_ROOT } from "../../config/config";
import { get } from "./base/index";

const getAggregatedData24hrs = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/aggregated/measurements/${params.site_id}/${params.name}`;
    return get(url, params);
  },
};

export { getAggregatedData24hrs };
