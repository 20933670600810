import React from "react";
import Select from "react-select";
import { useField } from "formik";

const MultiSelect = (props) => {
  const [field, meta] = useField(props);

  const handleChange = (value) => {
    props.onChange(field.name, value);
  };

  return <Select isClearable isSearchable isDisabled={props.disabled} isMulti options={props.options} onChange={handleChange} value={meta.value} />;
};

export default MultiSelect;
