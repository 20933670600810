import React from "react";
import { Col, Container, Row } from "reactstrap";
import Reports from "../containers/reports";

const ReportsPage = () => (
  <Container className="dashboard">
    <Row>
      <Col xl={12} lg={12} md={12} sm={12}>
        <h3 className="page-title">Reports</h3>
        <h3 className="page-subhead subhead">Build and run reports to export the sensor data for further analysis.</h3>
      </Col>
    </Row>
    <Reports />
  </Container>
);

export default ReportsPage;
