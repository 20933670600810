import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Spinner } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { loginAction, getUserOrganisationAction } from "../../redux/userActions";

//Icons
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";

const LogInForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginErr, setLoginErr] = useState(null);

  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const formValidation = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email.").required("Email is required."),
    password: Yup.string().required("Password is required."),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={formValidation}
        onSubmit={async (values) => {
          await props
            .loginAction(values)
            .then(() => {
              props.history.push("/devices");
            })
            .catch((error) => {
              //Login error
              setLoginErr(error.message);
            });
        }}
      >
        <Form>
          {loginErr && <Alert color="danger">{loginErr}</Alert>}
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field name="email" type="email" />
            </div>
            <div className="form__error_message">
              <ErrorMessage name="email" />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field name="password" type={showPassword ? "text" : "password"} autoComplete="off"/>
              <button className={`form__form-group-button${showPassword ? " active" : ""}`} onClick={(e) => togglePassword(e)} type="button">
                <EyeIcon />
              </button>
            </div>
            <div className="form__error_message">
              <ErrorMessage name="password" />
            </div>
          </div>
          <button className="btn btn-primary login__login_btn" type="submit">
            {props.isFetching ? <Spinner animation="border" className="login__loading_spinner" /> : "Login"}
          </button>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  loginAction: loginAction,
  getUserOrganisationAction: getUserOrganisationAction,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  isFetching: state.user.isFetching,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogInForm));
