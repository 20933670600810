import React from "react";
import { Col, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { runReportAction, getAllReportRunsAction, deleteReportAction } from "../reports/redux/reportActions";
import RunReportModal from "./components/RunReportModal";
import ReportRunTable from "./components/reportRunsTable";
import ModalComponent from "../../shared/components/modal/modal";
import DeleteIcon from "mdi-react/TrashCanOutlineIcon";
import ToastifyNotification from "../../shared/components/notification";

class ReportDetails extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toolTipOpen: false,
      modal: false,
      deleteModal: false,
    };
  }

  componentDidMount() {
    this.props.getAllReportRunsAction({ report_id: this.props.reportId });
  }

  runReport = () => {
    this.props.runReportAction(this.props.reportId);
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  deleteReport = () => {
    this.props.deleteReportAction({ report_id: this.props.reportId }).then(() => {
      ToastifyNotification("Report has been deleted");
      this.props.history.push("/reports");
    });
  };

  render() {
    const report = this.props.reports.filter((report) => report.id == this.props.reportId)[0];
    return (
      <React.Fragment>
        <Row>
          <Col lg={12}>
            <h3 className="page-title">{report.name}</h3>
            <h3 className="page-subhead subhead">Run, download and view report details.</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Report Details</h5>
                  <h5 className="subhead">View the device details using the edit button</h5>
                </div>
                <div className="reports__report_details_devices">
                  <p>Devices included in the report</p>
                  {report.meta.allDevices ? (
                    <span key={"device_all-devices"}>All Devices</span>
                  ) : (
                    report.meta.devices.map((device, index) => <span key={"device_" + index}>{device.label}</span>)
                  )}
                </div>
                <div className="reports__report_details_measurements">
                  <p>Measurements included in the report</p>
                  {report.meta.measurements.map((measurement, index) => (
                    <span key={"measurement_" + index}>{measurement.label}</span>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Report Actions</h5>
                </div>
                <div className="reports__report_actions_btn_wrapper">
                  <RunReportModal reportName={report.name} reportId={report.id} />
                  <Button className="icon" color="danger" onClick={this.toggleDeleteModal}>
                    <p>
                      <DeleteIcon />
                      Delete Report
                    </p>
                  </Button>
                  <ModalComponent
                    title={"Are you sure want to delete this report?"}
                    children={
                      <p>
                        By clicking on the ok button you confirm that you want to delete this report and all the previous runs. <strong>This action can not be undone.</strong>
                      </p>
                    }
                    modal={this.state.deleteModal}
                    onClick={this.deleteReport}
                    toggle={this.toggleDeleteModal}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Previous report runs</h5>
                  <h5 className="subhead">
                    Listed below are all the previous reports. You can download these without re-running the report. If you have just ran a report, refresh the page to see any
                    status change.
                  </h5>
                </div>
                {this.props.isFetching ? <Spinner /> : <ReportRunTable tableCardData={this.props.reportRuns} reportId={this.props.reportId} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports.reports,
  reportRuns: state.reports.reportRuns,
  isFetching: state.reports.isFetching,
});

const mapDispatchToProps = {
  runReportAction: runReportAction,
  getAllReportRunsAction: getAllReportRunsAction,
  deleteReportAction: deleteReportAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDetails));
