import React, { useState } from "react";
import Switch from "react-switch";

const ReactSwitch = (props) => {
  const [checked, setChecked] = useState(props.checked);

  //Update the checked site and update the parent permissions array
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    props.onChange({ id: props.permission_id, has_permission: nextChecked });
  };

  return (
    <label>
      <Switch
        height={22}
        width={42}
        handleDiameter={18}
        offColor="#d0d0d0"
        onColor="#0faea0"
        uncheckedIcon={false}
        checkedIcon={false}
        onChange={handleChange}
        checked={checked}
        className="react-switch"
      />
    </label>
  );
};

export default ReactSwitch;
