import { handleActions } from "redux-actions";
import {
  getSiteAveragesFailure,
  getSiteAveragesRequest,
  getSiteAveragesSuccess,
  getSiteLocationsFailure,
  getSiteLocationsRequest,
  getSiteLocationsSuccess,
  getSiteLocationsPerformanceRequest,
  getSiteLocationsPerformanceFailure,
  getSiteLocationsPerformanceSuccess,
  getAllSitesRequest,
  getAllSitesSuccess,
  getAllSitesFailure,
} from "./siteActions";

const defaultState = {
  sites: null,
  siteAverages: null,
  locations: null,
  LocationPerformance: null,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [getSiteAveragesRequest](state) {
      return {
        ...state,
        siteAverages: null,
        isFetching: true,
        error: null,
      };
    },
    [getSiteAveragesSuccess](state, { payload }) {
      return {
        ...state,
        siteAverages: payload.length > 0 ? payload : null,
        isFetching: false,
        error: null,
      };
    },
    [getSiteAveragesFailure](state, { payload }) {
      return {
        ...state,
        siteAverages: null,
        isFetching: false,
        error: payload,
      };
    },
    [getSiteLocationsRequest](state) {
      return {
        ...state,
        locations: null,
        isFetching: true,
        error: null,
      };
    },
    [getSiteLocationsSuccess](state, { payload }) {
      return {
        ...state,
        locations: payload,
        isFetching: false,
        error: null,
      };
    },
    [getSiteLocationsFailure](state, { payload }) {
      return {
        ...state,
        locations: null,
        isFetching: false,
        error: payload,
      };
    },
    [getSiteLocationsPerformanceRequest](state) {
      return {
        ...state,
        locationPerformance: null,
        isFetching: true,
        error: null,
      };
    },
    [getSiteLocationsPerformanceSuccess](state, { payload }) {
      return {
        ...state,
        LocationPerformance: payload,
        isFetching: false,
        error: null,
      };
    },
    [getSiteLocationsPerformanceFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        locationPerformance: null,
        error: payload,
      };
    },
    [getAllSitesRequest](state) {
      return {
        ...state,
        sites: null,
        payload: null,
        isFetching: true,
        error: null,
      };
    },
    [getAllSitesSuccess](state, { payload }) {
      return {
        ...state,
        sites: payload,
        isFetching: false,
        error: null,
      };
    },
    [getAllSitesFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        sites: null,
        error: payload,
      };
    },
  },
  defaultState
);
