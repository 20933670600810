import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SidebarContent from "./SidebarContent";

const Sidebar = ({ changeMobileSidebarVisibility, sidebar }) => {
  const sidebarClass = classNames({
    sidebar: true,
    "sidebar--show": sidebar.show,
    "sidebar--collapse": sidebar.collapse,
  });

  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent onClick={() => {}} />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent onClick={changeMobileSidebarVisibility} />
        </div>
    </div>
  );
};

Sidebar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
