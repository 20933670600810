import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import DataTable from "../../../shared/components/table/dataTable";

const SiteLocationsTable = ({ tableCardData }) => {
  const tableHeaders = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "total_devices",
      headerName: "Total Devices",
      flex: 1,
      minWidth: 150,
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element) => {
        array.push({
          id: element.id,
          name: element.name,
          total_devices: element.total_devices,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} tableSize={4} disableFiltering={false} showToolbar={false} rowOnClickPath={"/location/id"} />;
};

export default SiteLocationsTable;
