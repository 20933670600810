import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

//
//
//
//Get all organisations
export const getOrganisationsRequest = createAction("GET_ORGANISATIONS_REQUEST");
export const getOrganisationsSuccess = createAction("GET_ORGANISATIONS_SUCCESS");
export const getOrganisationsFailure = createAction("GET_ORGANISATIONS_FAILURE");

export const getOrganisationsAction = (organisation_id) => async (dispatch, getState) => {
  try {
    dispatch(getOrganisationsRequest());
    const response = organisation_id ? await api.getOrganisation.get({ organisation_id: organisation_id }) : await api.getOrganisations.get();
    const organisationData = {
      organisations: response.data,
    };
    dispatch(getOrganisationsSuccess(organisationData));
  } catch (error) {
    dispatch(getOrganisationsFailure({ error }));
    throw error;
  }
};

//
//
//
//Get all roles
export const getRolesRequest = createAction("GET_ROLES_REQUEST");
export const getRolesSuccess = createAction("GET_ROLES_SUCCESS");
export const getRolesFailure = createAction("GET_ROLES_FAILURE");

export const getRolesAction = () => async (dispatch, getState) => {
  try {
    dispatch(getRolesRequest());
    const response = await api.getRoles.get();
    const rolesData = {
      roles: response.data,
    };
    dispatch(getRolesSuccess(rolesData));
  } catch (error) {
    dispatch(getRolesFailure({ error }));
    throw error;
  }
};

//
//
//
//Register a new organisation
export const registerOrganisationRequest = createAction("REGISTER_ORGANISATION_REQUEST");
export const registerOrganisationSuccess = createAction("REGISTER_ORGANISATION_SUCCESS");
export const registerOrganisationFailure = createAction("REGISTER_ORGANISATION_FAILURE");

export const registerOrganisationAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(registerOrganisationRequest());
    const response = await api.registerOrganisation.post(values);
    dispatch(registerOrganisationSuccess(response.data));
  } catch (error) {
    dispatch(registerOrganisationFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};

//
//
//
//Enroll a new user. Sends the new user a activation email
export const enrollUserRequest = createAction("ENROLL_USER_REQUEST");
export const enrollUserSuccess = createAction("ENROLL_USER_SUCCESS");
export const enrollUserFailure = createAction("ENROLL_USER_FAILURE");

export const enrollUserAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(enrollUserRequest());
    const response = await api.enrollUser.post(values);
    dispatch(enrollUserSuccess(response.data));
  } catch (error) {
    dispatch(enrollUserFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Enroll a new device under an organisation
export const enrollDeviceRequest = createAction("ENROLL_DEVICE_REQUEST");
export const enrollDeviceSuccess = createAction("ENROLL_DEVICE_SUCCESS");
export const enrollDeviceFailure = createAction("ENROLL_DEVICE_FAILURE");

export const enrollDeviceAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(enrollDeviceRequest());
    const response = await api.enrollDevice.post(values);
    dispatch(enrollDeviceSuccess(response.data));
  } catch (error) {
    dispatch(enrollDeviceFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get device models
export const getModelsRequest = createAction("GET_MODELS_REQUEST");
export const getModelsSuccess = createAction("GET_MODELS_SUCCESS");
export const getModelsFailure = createAction("GET_MODELS_FAILURE");

export const getDeviceModelsAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getModelsRequest());
    const response = await api.getDeviceModels.get(values);
    const deviceModelData = {
      deviceModelList: response.data,
    };
    dispatch(getModelsSuccess(deviceModelData));
  } catch (error) {
    dispatch(getModelsFailure({ error }));
    throw error;
  }
};

//
//
//
//Create new site under a organisation
export const createSiteRequest = createAction("CREATE_SITE_REQUEST");
export const createSiteSuccess = createAction("CREATE_SITE_SUCCESS");
export const createSiteFailure = createAction("CREATE_SITE_FAILURE");

export const createSiteRequestAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(createSiteRequest());
    const response = await api.createSite.post(values);
    dispatch(createSiteSuccess(response.data));
  } catch (error) {
    dispatch(createSiteFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get User Site permissions
export const getUserSitePermissionsRequest = createAction("GET_USER_SITE_PERMISSIONS_REQUEST");
export const getUserSitePermissionsSuccess = createAction("GET_USER_SITE_PERMISSIONS_SUCCESS");
export const getUserSitePermissionsFailure = createAction("GET_USER_SITE_PERMISSIONS_FAILURE");

export const getUserSitePermissionsAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getUserSitePermissionsRequest());
    const response = await api.getUserSitePermissions.get(values);
    dispatch(getUserSitePermissionsSuccess(response.data));
  } catch (error) {
    dispatch(getUserSitePermissionsFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Update user Site permissions
export const updateUserSitePermissionsRequest = createAction("UPDATE_USER_SITE_PERMISSIONS_REQUEST");
export const updateUserSitePermissionsSuccess = createAction("UPDATE_USER_SITE_PERMISSIONS_SUCCESS");
export const updateUserSitePermissionsFailure = createAction("UPDATE_USER_SITE_PERMISSIONS_FAILURE");

export const updateUserSitePermissionsAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(updateUserSitePermissionsRequest());
    const response = await api.updateUserSitePermissions.post(values);
    dispatch(updateUserSitePermissionsSuccess(response.data));
  } catch (error) {
    dispatch(updateUserSitePermissionsFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Delete User
export const deleteUserRequest = createAction("DELETE_USER_REQUEST");
export const deleteUserSuccess = createAction("DELETE_USER_SUCCESS");
export const deleteUserFailure = createAction("DELETE_USER_FAILURE");

export const deleteUserAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(deleteUserRequest());
    const response = await api.deleteUser._delete(values);
    dispatch(deleteUserSuccess(response.data));
  } catch (error) {
    dispatch(deleteUserFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Update User
export const updateUserRequest = createAction("UPDATE_USER_REQUEST");
export const updateUserSuccess = createAction("UPDATE_USER_SUCCESS");
export const updateUserFailure = createAction("UPDATE_USER_FAILURE");

export const updateUserAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(updateUserRequest());
    const response = await api.updateUser.put(values);
    dispatch(updateUserSuccess(response.data));
  } catch (error) {
    dispatch(updateUserFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get all users
export const getUsersRequest = createAction("GET_USERS_REQUEST");
export const getUsersSuccess = createAction("GET_USERS_SUCCESS");
export const getUsersFailure = createAction("GET_USERS_FAILURE");

export const getUsersAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getUsersRequest());
    const response = await api.getUsers.get();
    dispatch(getUsersSuccess(response.data));
  } catch (error) {
    dispatch(getUsersFailure({ error }));
    console.log(error);
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};
