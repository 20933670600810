import React from "react";
import { Col } from "reactstrap";
import { Card, CardBody } from "reactstrap";
// import AddNewUserModel from "../users/components/addNewUserModel";
import AddNewUserForm from "./components/addNewUserForm";

// do we want to use the same model as is used for managing add user or is there a reason we want admin to have a form with different cases ? 

const AddNewUser = () => {
  return (
    <Col xl={12} lg={12} md={12} sm={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Add a new user to any organisation</h5>
            <h5 className="subhead">Fill out the form below with user details. Once you add the new user they will receive a email to complete the account set up.</h5>
          </div>
          {/* <AddNewUserModel /> */}
          <AddNewUserForm />

        </CardBody>
      </Card>
    </Col>
  );
};

export default AddNewUser;
