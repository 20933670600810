import React from "react";
import { Col, Card, CardBody, Row, Spinner } from "reactstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";

import SiteLocationsTable from "./components/siteLocationsTable";
import { getSiteAveragesAction, getSiteLocationsAction, getSiteLocationsPerformanceAction } from "../sites/redux/siteActions";
import LocationPerformance from "./components/locationPerformance";
import HalfGuageChart from "../../components/charts/halfGuageChart";
import NavigationIcon from "mdi-react/NavigationIcon";
import CellphoneLinkIcon from "mdi-react/CellphoneLinkIcon";
import { environment_variable_meta } from "../../config/config";

class SiteOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getSiteAveragesAction({ site_id: this.props.site_id });
    this.props.getSiteLocationsAction({ site_id: this.props.site_id });
    this.props.getSiteLocationsPerformanceAction({ site_id: this.props.site_id });
  }

  render() {
    const totalDevices = this.props.devices && this.props.devices.filter((device) => device.site.id == this.props.site_id).length;
    const totalLocations = this.props.siteLocations && this.props.siteLocations.length;

    return this.props.isFetching ? (
      <LoadingSpinner loadingText="Loading devices..." />
    ) : (
      <React.Fragment>
        <Row>
          <Col xl="2" lg="3" md="6" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">CO2</h5>
                  <h5 className="subhead">Average since 8AM today</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.siteAverages ? (
                  <React.Fragment>
                    <HalfGuageChart value={Math.round(this.props.siteAverages[0].value)} unit="ppm" threshold={environment_variable_meta.CO2.threshold} />
                    <div className="site__max_value">
                      <span>Max: {environment_variable_meta.CO2.threshold}ppm</span>
                    </div>
                  </React.Fragment>
                ) : (
                  "No data."
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="6" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">TVOC</h5>
                  <h5 className="subhead">Average since 8AM today</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.siteAverages ? (
                  <React.Fragment>
                    <HalfGuageChart value={Math.round(this.props.siteAverages[3].value)} unit="ppb" threshold={environment_variable_meta.TVOC.threshold} />
                    <div className="site__max_value">
                      <span>Max: {environment_variable_meta.TVOC.threshold}ppb</span>
                    </div>
                  </React.Fragment>
                ) : (
                  "No data."
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="6" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Temperature</h5>
                  <h5 className="subhead">Average since 8AM today</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.siteAverages ? (
                  <React.Fragment>
                    <HalfGuageChart value={Math.round(this.props.siteAverages[2].value)} unit="°C" threshold={environment_variable_meta.Temperature.threshold} />
                    <div className="site__max_value">
                      <span>Max: {environment_variable_meta.Temperature.threshold}°C</span>
                    </div>
                  </React.Fragment>
                ) : (
                  "No data."
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="2" lg="3" md="6" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Humidity</h5>
                  <h5 className="subhead">Average since 8AM today</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.siteAverages ? (
                  <React.Fragment>
                    <HalfGuageChart value={Math.round(this.props.siteAverages[1].value)} unit="%" threshold={environment_variable_meta.Humidity.threshold} />
                    <div className="site__max_value">
                      <span>Max: {environment_variable_meta.Humidity.threshold}%</span>
                    </div>
                  </React.Fragment>
                ) : (
                  "No data."
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" lg="12" md="12" sm="12">
            <Card>
              <CardBody>
                <div className="site__total_count_wrapper">
                  <div className="site_total_count_content border-right">
                    <CellphoneLinkIcon />
                    <span>{totalDevices}</span> Total Devices
                  </div>
                  <div className="site_total_count_content">
                    <NavigationIcon />
                    <span>{totalLocations}</span> Total Locations
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="3" lg="6" md="6" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Worst Location</h5>
                  <h5 className="subhead">Location with the Highest Co2 average since 8AM today</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.locationPerformance && this.props.locationPerformance.length > 0 ? (
                  <LocationPerformance data={this.props.locationPerformance} best={false} worst={true} />
                ) : (
                  "No data."
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" lg="6" md="6" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Best Location</h5>
                  <h5 className="subhead">Location with the lowest Co2 average since 8AM today</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.locationPerformance && this.props.locationPerformance.length > 0 ? (
                  <LocationPerformance data={this.props.locationPerformance} best={true} worst={false} />
                ) : (
                  "No data."
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl="6" lg="12" md="12" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Locations</h5>
                  <h5 className="subhead">All locations at this site</h5>
                </div>
                <div className="site_locations_table">
                  <SiteLocationsTable tableCardData={this.props.siteLocations} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  devices: state.devices.activeDevices,
  siteAverages: state.site.siteAverages,
  siteLocations: state.site.locations,
  isFetching: state.site.isFetching,
  locationPerformance: state.site.LocationPerformance,
});

const mapDispatchToProps = {
  getSiteAveragesAction: getSiteAveragesAction,
  getSiteLocationsAction: getSiteLocationsAction,
  getSiteLocationsPerformanceAction: getSiteLocationsPerformanceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteOverview);
