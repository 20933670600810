import { API_ROOT } from "../../config/config";
import { get, post, put } from "./base/index";

const getDevices = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/devices/`;
    return get(url, params);
  },
};

const getSiteDevices = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site_devices/`;
    return get(url, params);
  },
};

const getDevice = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/devices/${params.device_id}`;
    return get(url, params);
  },
};

const activateDevice = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/devices/activate/`;
    return post(url, params);
  },
};

const updateDevice = {
  put: (data, device_id) => {
    const params = { ...data, device_id: device_id };
    const url = `${API_ROOT}/devices/update/`;
    return put(url, params);
  },
};

const getOrgLocations = {
  get: (org_id) => {
    const url = `${API_ROOT}/locations/${org_id}`;
    return get(url);
  },
};

const getDeviceMeasurements = {
  get: (data) => {
    const params = { ...data };
    const url = params.amount ? `${API_ROOT}/measurements/device_details/${params.device_id}/${params.amount}` : `${API_ROOT}/measurements/${params.device_id}`;
    return get(url, params);
  },
};

const getLatestDeviceMeasurements = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/latest/${params.site_id}`;
    return get(url, params);
  },
};

export { getDevices, getDevice, activateDevice, updateDevice, getDeviceMeasurements, getOrgLocations, getLatestDeviceMeasurements, getSiteDevices };
