import { ErrorMessage, Field, Form, Formik } from "formik";
import CloseIcon from "mdi-react/CloseIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React from "react";
import { connect } from "react-redux";
import { Alert, Button, ButtonGroup } from "reactstrap";
import { getOrganisationsAction, getRolesAction, getUsersAction, updateUserAction } from "../../redux/adminActions";
import * as Yup from "yup";
import ToastifyNotification from "../../../../shared/components/notification";

class UserInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      editable: false,
      formError: null,
    };
  }

  toggleFormEdit = () => {
    this.setState({
      editable: !this.state.editable,
    });
  };

  formValidation = () =>
    Yup.object().shape({
      id: Yup.string().required("User is required"),
      email: Yup.string().email("Please enter a valid email").required("Please selected a role"),
      role: Yup.string().required("Please selected a role"),
    });

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            id: this.props.user.id,
            email: this.props.user.email,
            role: this.props.user.role.id,
          }}
          validationSchema={this.formValidation}
          onSubmit={async (values) => {
            this.toggleFormEdit();
            this.props
              .updateUserAction(values)
              .then(() => {
                this.props
                  .getUsersAction()
                  .then(() => ToastifyNotification("Users information has been updated"))
                  .catch((error) => console.log(error));
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  formError: error.message,
                });
              });
          }}
        >
          {({ setErrors, resetForm }) => (
            <Form>
              {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
              <div className="form__form-group">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <Field name="email" component="input" type="email" placeholder={this.props.user.email} disabled={!this.state.editable} />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="email" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Role</span>
                <div className="form__form-group-field">
                  {this.state.editable ? (
                    <React.Fragment>
                      <Field name="role" as="select">
                        <option></option>
                        {this.props.loggedInUSer.role.id === 1
                          ? this.props.roles &&
                            this.props.roles.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.role}
                              </option>
                            ))
                          : this.props.roles &&
                            this.props.roles
                              .filter((role) => role.id !== 1)
                              .map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.role}
                                </option>
                              ))}
                      </Field>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Field name="role_dummy" component="input" type="text" placeholder={this.props.user.role.role} disabled={true} />
                    </React.Fragment>
                  )}
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="role" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Account Created</span>
                <div className="form__form-group-field">
                  <p>{this.props.user.created}</p>
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="created" />
                </div>
              </div>
              <div className="form__form_edit_btn_wrapper">
                {this.state.editable ? (
                  <ButtonGroup>
                    <Button className="icon" color="success" type="submit">
                      <p>
                        <ContentSaveIcon /> Save changes
                      </p>
                    </Button>
                    <Button
                      className="icon"
                      color="danger"
                      onClick={() => {
                        this.toggleFormEdit();

                        //Call default Formik handlers
                        setErrors({});
                        resetForm();
                      }}
                    >
                      <p>
                        <CloseIcon /> Cancel
                      </p>
                    </Button>
                  </ButtonGroup>
                ) : (
                  <Button className="icon" color="primary" onClick={this.toggleFormEdit}>
                    <p>
                      <PencilIcon /> Edit
                    </p>
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.admin.roleList,
  isFetching: state.admin.isFetching,
  loggedInUSer: state.user.user,
});

const mapDispatchToProps = {
  getRolesAction: getRolesAction,
  updateUserAction: updateUserAction,
  getUsersAction: getUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);
