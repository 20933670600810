import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

//
//
//
//Create Report
export const createReportRequest = createAction("CREATE_REPORT_REQUEST");
export const createReportSuccess = createAction("CREATE_REPORT_SUCCESS");
export const createReportFailure = createAction("CREATE_REPORT_FAILURE");

export const createReportAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(createReportRequest());
    const response = await api.createReport.post({ values });
    const reportMetaData = {
      reportResponse: response.data,
    };
    dispatch(createReportSuccess(reportMetaData));
  } catch (error) {
    dispatch(createReportFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get Report
export const getReportRequest = createAction("GET_REPORT_REQUEST");
export const getReportSuccess = createAction("GET_REPORT_SUCCESS");
export const getReportFailure = createAction("GET_REPORT_FAILURE");

export const getReportAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getReportRequest());
    const response = await api.getReport.get({ values });
    const reports = response.data;
    dispatch(getReportSuccess(reports));
  } catch (error) {
    dispatch(getReportFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};

//
//
//
//Run Report
export const runReportRequest = createAction("RUN_REPORT_REQUEST");
export const runReportSuccess = createAction("RUN_REPORT_SUCCESS");
export const runReportFailure = createAction("RUN_REPORT_FAILURE");

export const runReportAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(runReportRequest());
    const response = await api.runReport.post(values);
    const reports = response.data;
    dispatch(runReportSuccess(reports));
  } catch (error) {
    dispatch(runReportFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Get report runs
export const getAllReportRunsRequest = createAction("GET_ALL_REPORT_RUNS_REQUEST");
export const getAllReportRunsSuccess = createAction("GET_ALL_REPORT_RUNS_SUCCESS");
export const getAllReportRunsFailure = createAction("GET_ALL_REPORT_RUNS_FAILURE");

export const getAllReportRunsAction = (report_id) => async (dispatch, getState) => {
  try {
    dispatch(getAllReportRunsRequest());
    const response = await api.getAllReportRuns.get(report_id);
    const responseData = response.data;
    dispatch(getAllReportRunsSuccess(responseData));
  } catch (error) {
    dispatch(getAllReportRunsFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};

//
//
//
//Get report runs
export const downloadReportRequest = createAction("DOWNLOAD_REPORT_REQUEST");
export const downloadReportSuccess = createAction("DOWNLOAD_REPORT_SUCCESS");
export const downloadReportFailure = createAction("DOWNLOAD_REPORT_FAILURE");

export const downloadReportAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(downloadReportRequest());
    const response = await api.downloadReport.get(values);
    const responseData = response.data;
    dispatch(downloadReportSuccess(responseData));
    return responseData;
  } catch (error) {
    dispatch(downloadReportFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};

//
//
//
//Delete report run
export const deleteReportRunRequest = createAction("DELETE_REPORT_RUN_REQUEST");
export const deleteReportRunSuccess = createAction("DELETE_REPORT_RUN_SUCCESS");
export const deleteReportRunFailure = createAction("DELETE_REPORT_RUN_FAILURE");

export const deleteReportRunAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(deleteReportRunRequest());
    const response = await api.deleteReportRun._delete(values);
    const responseData = response.data;
    dispatch(deleteReportRunSuccess(responseData));
    return responseData;
  } catch (error) {
    dispatch(deleteReportRunFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};

//
//
//
//Delete report
export const deleteReportRequest = createAction("DELETE_REPORT_REQUEST");
export const deleteReportSuccess = createAction("DELETE_REPORT_SUCCESS");
export const deleteReportFailure = createAction("DELETE_REPORT_FAILURE");

export const deleteReportAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(deleteReportRequest());
    const response = await api.deleteReport._delete(values);
    const responseData = response.data;
    dispatch(deleteReportSuccess(responseData));
    return responseData;
  } catch (error) {
    dispatch(deleteReportFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};
