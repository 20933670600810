import React from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EyeIcon from "mdi-react/EyeIcon";
import ChartBoxOutlineIcon from "mdi-react/ChartBoxOutlineIcon";
import DataTable from "../../../../shared/components/table/dataTable";

const CurrentMeasurementsTable = ({ tableCardData, history }) => {
  const build_measurement_cell = (params) => {
    var units = { CO2: "ppm", TVOC: "ppb", temperature: "°C", humidity: "%", pressure: "Pa", illumination: "lux" };
    return Object.entries(params.row).map((item) => {
      if (!["id", "name", "timestamp", "battery_level"].includes(item[0]))
        return (
          <div className="latest_measurements__value_tab">
            <span className="latest_measurements__name">{item[0]}</span>
            <span className="latest_measurements__value">
              {item[1]} {units[item[0]]}
            </span>
          </div>
        );
    });
  };

  const tableHeaders = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "CO2",
      headerName: "CO2",
      type: "number",
      hide: true,
    },
    {
      field: "TVOC",
      headerName: "TVOC",
      type: "number",
      hide: true,
    },
    {
      field: "temperature",
      headerName: "Temperature",
      type: "number",
      hide: true,
    },
    {
      field: "humidity",
      headerName: "Humidity",
      type: "number",
      hide: true,
    },
    {
      field: "illumination",
      headerName: "Illumination",
      type: "number",
      hide: true,
    },
    {
      field: "pressure",
      headerName: "Pressure",
      type: "number",
      hide: true,
    },
    {
      field: "measurements",
      headerName: "Measurements",
      flex: 3,
      minWidth: 550,
      sortable: false,

      renderCell: (params) => build_measurement_cell(params),
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      minWidth: 150,
    },
    {
      field: "battery_level",
      headerName: "Battery",
      type: "number",
      minWidth: 125,
      valueFormatter: (params) => {
        if (params.value == null) return "0%";
        else return `${params.value}%`;
      },
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 10,
      getActions: (params) => [
        <GridActionsCellItem icon={<EyeIcon />} label="View Device" onClick={() => history.push(`/devices/${params.id}`)} showInMenu />,
        <GridActionsCellItem icon={<ChartBoxOutlineIcon />} label="View Dashboard" onClick={() => history.push(`/devices/${params.id}/dashboard`)} showInMenu />,
      ],
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element, index) => {
        array.push({
          id: element.id,
          name: element.name,
          CO2: element.CO2,
          TVOC: element.TVOC,
          temperature: element.temperature,
          humidity: element.humidity,
          pressure: element.pressure,
          illumination: element.illumination,
          timestamp: element.timestamp,
          battery_level: element.battery_level,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} rowOnClickPath={"/devices/id"} tableSize={10} disableFiltering={false} showToolbar={true} />;
};

export default CurrentMeasurementsTable;
