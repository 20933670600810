import React from "react";
import { Col } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import NewOrganisationForm from "./components/newOrganisationForm";

const NewOrganisationFrom = () => {
  return (
    <Col xl={12} lg={12} md={12} sm={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Add a new organisation</h5>
            <h5 className="subhead">Fill out the form below with the new organisation details. All fields must be supplied.</h5>
          </div>
          <NewOrganisationForm />
        </CardBody>
      </Card>
    </Col>
  );
};

export default NewOrganisationFrom;
