import { handleActions } from "redux-actions";
import {
  createLocationFailure,
  createLocationRequest,
  createLocationSuccess,
  updateLocationRequest,
  updateLocationSuccess,
  updateLocationFailure,
  getLocationDevicesSuccess,
  getLocationDevicesRequest,
  getLocationDevicesFailure,
  getLocationRequest,
  getLocationFailure,
  getLocationSuccess,
  getAllLocationsRequest,
  getAllLocationsSuccess,
  getAllLocationsFailure,
} from "./locationActions";

const defaultState = {
  isFetching: false,
  all_locations: null,
  location: null,
  devices: null,
};

export default handleActions(
  {
    [createLocationRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [createLocationSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [createLocationFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [updateLocationRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateLocationSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [updateLocationFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getLocationDevicesRequest](state) {
      return {
        ...state,
        isFetching: true,
        devices: null,
        error: null,
      };
    },
    [getLocationDevicesSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        devices: payload,
        error: null,
      };
    },
    [getLocationDevicesFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        devices: null,
        error: payload,
      };
    },
    [getLocationRequest](state) {
      return {
        ...state,
        isFetching: true,
        location: null,
        error: null,
      };
    },
    [getLocationSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        location: payload,
        error: null,
      };
    },
    [getLocationFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        location: null,
        error: payload,
      };
    },
    [getAllLocationsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getAllLocationsSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        all_locations: payload,
        error: null,
      };
    },
    [getAllLocationsFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        all_locations: null,
        error: payload,
      };
    },
  },
  defaultState
);
