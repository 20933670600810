import { API_ROOT } from "../../config/config";
import { post, put, get } from "./base/index";

const createLocation = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/location/add`;
    return post(url, params);
  },
};

const updateLocation = {
  put: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/location/update`;
    return put(url, params);
  },
};

const getLocation = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/location/get/${params.location_id}`;
    return get(url, params);
  },
};

const getLocationDevices = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/location/${params.location_id}/devices`;
    return get(url, params);
  },
};

const getAllLocations = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/location/get`;
    return get(url, params);
  },
};

export { createLocation, updateLocation, getLocation, getLocationDevices, getAllLocations };
