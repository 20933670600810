import React from "react";
import { Card, CardBody } from "reactstrap";
import UserActivationForm from "./components/userActivationForm";

const UserActivation = () => {
  return (
    <div className="login">
      <div className="login__wrapper">
        <Card className="login__card">
          <CardBody>
            <div className="card__title">
              <h3 className="login__title">Activate account</h3>
              <h5 className="subhead">You have been invited to join the IoT platform.<br></br>  Please enter and confirm a password for your account below.</h5>
            </div>
            <UserActivationForm />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default UserActivation;
