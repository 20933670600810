import React, { useState } from "react";
import { Alert, Card, CardBody, Spinner } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { requestPasswordResetAction } from "../../redux/userActions";

const ResetPasswordRequestForm = (props) => {
  //Local state
  const [formErr, setFormErr] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const formValidation = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email.").required("Email is required."),
  });

  return (
    <React.Fragment>
      <div className="password_reset__request_form_container">
        <div className="password_reset__request_form_wrapper">
          <div className="password_reset__request_form_card">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h3 className="login__title">Password Reset</h3>
                  <h5 className="subhead">Enter the email below associated with your account</h5>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    url: window.location.origin,
                  }}
                  validationSchema={formValidation}
                  onSubmit={async (values) => {
                    props
                      .requestPasswordResetAction(values)
                      .then(setSuccessMessage("If your email address is recongised you will receive a password reset email shortly"))
                      .catch((error) => setFormErr(error.message));
                  }}
                >
                  <Form>
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {formErr && <Alert color="danger">{formErr}</Alert>}
                    <div className="form__form-group">
                      <span className="form__form-group-label">Email</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AccountOutlineIcon />
                        </div>
                        <Field name="email" type="email" />
                      </div>
                      <div className="form__error_message">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                    <button className="btn btn-primary login__login_btn" type="submit">
                      {props.isFetching ? <Spinner animation="border" className="login__loading_spinner" /> : "Reset Password"}
                    </button>
                  </Form>
                </Formik>
                <Link to="/login">
                  <span className="password_reset__login">
                    Have you Remembered your password?<strong> <br></br>Login</strong>
                  </span>
                </Link>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  requestPasswordResetAction: requestPasswordResetAction,
};

const mapStateToProps = (state) => ({
  isFetching: state.user.isFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordRequestForm);
