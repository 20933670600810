import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Ava from "../assets/img/profile-avatar.png";
import ResetPasswordForm from "../containers/user/resetPassword/components/ResetPasswordForm";

const userProfile = ({ user, org }) => (
  <Container className="dashboard">
    <Row>
      <Col xl={12} lg={12} md={12} sm={12}>
        <h3 className="page-title">User Profile</h3>
        <h3 className="page-subhead subhead">View you user profile details</h3>
      </Col>
    </Row>
    <Row>
      <Col xl={6} lg={6} md={12} sm={12}>
        <Card>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div className="profile__avatar">
                <img src={Ava} alt="avatar" />
              </div>
              <div className="profile__data">
                <p className="profile__name">
                  {user.firstname} {user.surname}
                </p>
                <p className="profile__company">{org.name}</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={6} lg={6} md={12} sm={12}>
        <Card>
          <CardBody className="profile__user_information">
            <div className="profile__user_information_details">
              <p className="profile__user_information_details_head">Email address</p>
              <p className="profile__user_information_details_content">{user.email}</p>
            </div>
            <div className="profile__user_information_details">
              <p className="profile__user_information_details_head">Account Role</p>
              <p className="profile__user_information_details_content">{user.role.role}</p>
            </div>
            <div className="profile__user_information_details">
              <p className="profile__user_information_details_head">Account Created</p>
              <p className="profile__user_information_details_content">{user.created.split("T").join(" ")}</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Change your password</h5>
              <h5 className="subhead">Update your password using the form below</h5>
            </div>
            <ResetPasswordForm />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

const mapStateToProps = (state) => ({
  user: state.user.user,
  org: state.user.organisation,
});

export default connect(mapStateToProps)(userProfile);
