import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "../reducer/reducer";

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];

//Toggle Redux dev tools
// NODE_ENV is the default env variable more info here https://create-react-app.dev/docs/adding-custom-environment-variables/
//Add the logger middleware if the env is dev
if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));

export const persistor = persistStore(store);

export default store;
