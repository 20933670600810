import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

function ProtectedRoute({ component: Component, ...rest }) {
    
    const isAuth = useSelector(state => state.user.isAuthenticated)

    return (
        <Route 
            {...rest} 
            render={(props) => {
                if (isAuth) {
                    return <Component />;
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: { from: props.location} }} />
                    );
                }
            }}
        />
    );
}

export default ProtectedRoute;
