import React from "react";
import { Col, Container, Row } from "reactstrap";
import Users from "../../containers/admin/users";

const ManageUsersView = () => (
  <Container className="dashboard">
    <Row>
      <Col lg="12" md="12" sm="12">
        <h3 className="page-title">Manage Users</h3>
        <h3 className="page-subhead subhead">Listed below are all users</h3>
      </Col>
    </Row>
    <Users />
  </Container>
);

export default ManageUsersView;
