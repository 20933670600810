import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

//
//
//
//Create new location
export const createLocationRequest = createAction("CREATE_LOCATION_REQUEST");
export const createLocationSuccess = createAction("CREATE_LOCATION_SUCCESS");
export const createLocationFailure = createAction("CREATE_LOCATION_FAILURE");

export const createLocationAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(createLocationRequest());
    const response = await api.createLocation.post(values);
    dispatch(createLocationSuccess(response.data));
  } catch (error) {
    dispatch(createLocationFailure({ error }));
    console.log(error);
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//Update new location
export const updateLocationRequest = createAction("UPDATE_LOCATION_REQUEST");
export const updateLocationSuccess = createAction("UPDATE_LOCATION_SUCCESS");
export const updateLocationFailure = createAction("UPDATE_LOCATION_FAILURE");

export const updateLocationAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(updateLocationRequest());
    const response = await api.updateLocation.put(values);
    dispatch(updateLocationSuccess(response.data));
  } catch (error) {
    dispatch(updateLocationFailure({ error }));
    console.log(error);
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//get devices at location
export const getLocationDevicesRequest = createAction("GET_LOCATION_DEVICES_REQUEST");
export const getLocationDevicesSuccess = createAction("GET_LOCATION_DEVICES_SUCCESS");
export const getLocationDevicesFailure = createAction("GET_LOCATION_DEVICES_FAILURE");

export const getLocationDevicesAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getLocationDevicesRequest());
    const response = await api.getLocationDevices.get(values);
    dispatch(getLocationDevicesSuccess(response.data));
  } catch (error) {
    dispatch(getLocationDevicesFailure({ error }));
    console.log(error);
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//get devices at location
export const getLocationRequest = createAction("GET_LOCATION_REQUEST");
export const getLocationSuccess = createAction("GET_LOCATION_SUCCESS");
export const getLocationFailure = createAction("GET_LOCATION_FAILURE");

export const getLocationAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getLocationRequest());
    const response = await api.getLocation.get(values);
    dispatch(getLocationSuccess(response.data));
  } catch (error) {
    dispatch(getLocationFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};

//
//
//
//get devices at location
export const getAllLocationsRequest = createAction("GET_ALL_LOCATIONS_REQUEST");
export const getAllLocationsSuccess = createAction("GET_ALL_LOCATIONS_SUCCESS");
export const getAllLocationsFailure = createAction("GET_ALL_LOCATIONS_FAILURE");

export const getAllLocationsAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(getAllLocationsRequest());
    const response = await api.getAllLocations.get(values);
    dispatch(getAllLocationsSuccess(response.data));
  } catch (error) {
    dispatch(getAllLocationsFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data}`);
  }
};
