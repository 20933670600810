import React from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DoneIcon from "@mui/icons-material/Done";
import Chip from "@mui/material/Chip";

const Status = ({ status }) => {
  var icon;

  if (status == "Processing") {
    icon = <AutorenewIcon className="icon" />;
  } else if (status == "Completed") {
    icon = <DoneIcon className="icon" />;
  }
  let label = status;

  return <Chip className={status} icon={icon} size="small" label={label} variant="outlined" />;
};

export default Status;
