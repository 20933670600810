import { createAction } from "redux-actions";
import api from "../../../services/apiClient";

//
//
//
//Get devices - Only returns inactive devices that are part of the users organisation
export const getDevicesRequest = createAction("GET_DEVICES_REQUEST");
export const getDevicesSuccess = createAction("GET_DEVICES_SUCCESS");
export const getDevicesFailure = createAction("GET_DEVICES_FAILURE");

export const getDevicesAction = (device_id) => async (dispatch, getState) => {
  try {
    dispatch(getDevicesRequest());
    const response = await api.getDevices.get();
    dispatch(getDevicesSuccess(response.data));
  } catch (error) {
    dispatch(getDevicesFailure({ error }));
    throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
  }
};

//
//
//
//Get site devices - Only returns the active devices that are assigned to the site the user is part of
// export const getSiteDevicesRequest = createAction("GET_SITE_DEVICES_REQUEST");
// export const getSiteDevicesSuccess = createAction("GET_SITE_DEVICES_SUCCESS");
// export const getSiteDevicesFailure = createAction("GET_SITE_DEVICES_FAILURE");

// export const getSiteDevicesAction = () => async (dispatch, getState) => {
//   try {
//     dispatch(getSiteDevicesRequest());
//     const response = await api.getSiteDevices.get();
//     dispatch(getSiteDevicesSuccess(response.data));
//   } catch (error) {
//     dispatch(getSiteDevicesFailure({ error }));
//     throw new Error(`Error ${error.response.status}: ${error.response.data.data}`);
//   }
// };

//
//
//
//End user device activation
export const activateDeviceRequest = createAction("ACTIVATE_DEVICE_REQUEST");
export const activateDeviceSuccess = createAction("ACTIVATE_DEVICE_SUCCESS");
export const activateDeviceFailure = createAction("ACTIVATE_DEVICE_FAILURE");

export const activateDeviceAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(activateDeviceRequest());
    const response = await api.activateDevice.post(values);
    dispatch(activateDeviceSuccess(response.data));
  } catch (error) {
    dispatch(activateDeviceFailure({ error }));
    throw error;
  }
};

//
//
//
//Update device details
export const updateDeviceRequest = createAction("UPDATE_ORG_DEVICE_REQUEST");
export const updateDeviceSuccess = createAction("UPDATE_ORG_DEVICE_SUCCESS");
export const updateDeviceFailure = createAction("UPDATE_ORG_DEVICE_FAILURE");

export const updateDeviceAction = (values) => async (dispatch, getState) => {
  try {
    dispatch(updateDeviceRequest());
    const response = await api.updateDevice.put(values);
    const deviceData = {
      devices: response.data,
    };
    dispatch(updateDeviceSuccess(deviceData));
    //Re-fetch the device after a successful update
    dispatch(getDevicesAction());
  } catch (error) {
    dispatch(updateDeviceFailure({ error }));
    throw error;
  }
};

//
//
//
// Device Locations
export const getDeviceLocationsRequest = createAction("GET_ORG_LOCATION_REQUEST");
export const getDeviceLocationsSuccess = createAction("GET_ORG_LOCATION_SUCCESS");
export const getDeviceLocationsFailure = createAction("GET_ORG_LOCATION_FAILURE");

export const getDeviceLocationsAction = (org_id) => async (dispatch, getState) => {
  try {
    dispatch(getDeviceLocationsRequest());
    const response = await api.getOrgLocations.get(org_id);
    const locations = {
      locations: response.data,
    };
    dispatch(getDeviceLocationsSuccess(locations));
  } catch (error) {
    dispatch(getDeviceLocationsFailure({ error }));
    throw error;
  }
};

//
//
//
// Device measurements
export const getDeviceMeasurementsRequest = createAction("GET_DEVICE_MEASUREMENTS_REQUEST");
export const getDeviceMeasurementsSuccess = createAction("GET_DEVICE_MEASUREMENTS_SUCCESS");
export const getDeviceMeasurementsDetailsSuccess = createAction("GET_DEVICE_MEASUREMENTS_DETAILS_SUCCESS");
export const getDeviceMeasurementsFailure = createAction("GET_DEVICE_MEASUREMENTS_FAILURE");

export const getDeviceMeasurementsAction =
  (device_id, amount = null) =>
  async (dispatch, getState) => {
    try {
      dispatch(getDeviceMeasurementsRequest());
      const response = await api.getDeviceMeasurements.get({ device_id: device_id, amount: amount });
      const deviceMeasurementsData = {
        measurements: response.data,
      };
      if (amount) {
        dispatch(getDeviceMeasurementsDetailsSuccess(deviceMeasurementsData));
      } else {
        dispatch(getDeviceMeasurementsSuccess(deviceMeasurementsData));
      }
    } catch (error) {
      dispatch(getDeviceMeasurementsFailure({ error }));
      throw error;
    }
  };
