import React from "react";
import { Container } from "reactstrap";
import DeviceDetails from "../containers/deviceDetails";

const DeviceDetailsPage = (props) => (
  <Container className="dashboard">
    <DeviceDetails device_id={props.match.params.id} />
  </Container>
);

export default DeviceDetailsPage;
