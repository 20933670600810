import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import EnrollDeviceForm from "./components/enrollDeviceForm";
import { connect } from "react-redux";
import LoadingSpinner from "../../../shared/components/spinner/loading_spinner";
import { withRouter } from "react-router-dom";
import { getDeviceModelsAction, getOrganisationsAction } from "../redux/adminActions";
class EnrollDevice extends React.Component {
  componentDidMount() {
    this.props.getDeviceModelsAction();
    this.props.getOrganisationsAction();
  }

  render() {
    return (
      <Col xl={12} lg={12} md={12} sm={12}>
        {!this.props.orgList || !this.props.deviceModelList ? (
          <LoadingSpinner loadingText="Loading data..." />
        ) : this.props.orgList && this.props.deviceModelList ? (
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Enroll a new device</h5>
                <h5 className="subhead">
                  Pick the correct organisation from the list and enter the device EUI, if the organisation is not listed add an new one <a href="/admin/new-organisation">here</a>.
                </h5>
              </div>
              <EnrollDeviceForm organisations={this.props.orgList} deviceModels={this.props.deviceModelList} />
            </CardBody>
          </Card>
        ) : (
          "Error fetching data please try again"
        )}
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  orgList: state.admin.organisationList,
  deviceModelList: state.admin.deviceModelList,
  error: state.admin.error,
});

const mapDispatchToProps = {
  getDeviceModelsAction: getDeviceModelsAction,
  getOrganisationsAction: getOrganisationsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnrollDevice));
