import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { withRouter } from "react-router";
import CustomToolbar from "./components/toolbar";

const DataTable = ({ rows, columns, rowOnClickPath, history, tableSize, disableFiltering, isLoading, showToolbar }) => {
  const [pageSize, setPageSize] = useState(tableSize);
  const [searchText, setSearchText] = React.useState("");
  const [tableRows, setRows] = React.useState(rows);

  const handleRowClick = (rowParams) => {
    var path = rowOnClickPath.replace("id", rowParams.row.id);
    console.log(path);
    history.push(path);
  };

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          components={showToolbar && { Toolbar: CustomToolbar }}
          componentsProps={
            showToolbar && {
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }
          }
          loading={isLoading}
          hideFooter={rows.length > pageSize ? false : true}
          pagination
          autoHeight
          disableColumnFilter={disableFiltering}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 25, 50]}
          rows={tableRows}
          columns={columns}
          disableColumnSelector
          disableSelectionOnClick
          onRowClick={rowOnClickPath && handleRowClick}
        />
      </div>
    </div>
  );
};

export default withRouter(DataTable);
