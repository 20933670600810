import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import Sidebar from "./sidebar/Sidebar";
import TopBar from "./topbar/Topbar";

import { changeMobileSidebarVisibility, changeSidebarVisibility } from "./redux/sidebarActions";

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: PropTypes.shape({
      show: PropTypes.bool,
      collapse: PropTypes.bool,
    }),
  };

  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  render() {
    const { sidebar } = this.props;

    const layoutClass = classNames({
      layout: true,
      "layout--collapse": sidebar.collapse,
    });

    return (
      <div className={layoutClass}>
        <TopBar changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} changeSidebarVisibility={this.changeSidebarVisibility} />
        <Sidebar sidebar={sidebar} changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} />
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
  }))(Layout)
);
