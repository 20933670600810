import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import { withRouter } from "react-router";
import DataTable from "../../../shared/components/table/dataTable";
import EyeIcon from "mdi-react/EyeIcon";
import ChartBoxOutlineIcon from "mdi-react/ChartBoxOutlineIcon";

const LocationDevicesTable = ({ tableCardData, history }) => {
  const tableHeaders = [
    {
      field: "actions",
      type: "actions",
      minWidth: 10,
      getActions: (params) => [
        <GridActionsCellItem icon={<EyeIcon />} label="View Device" onClick={() => history.push(`/devices/${params.id}`)} showInMenu />,
        <GridActionsCellItem icon={<ChartBoxOutlineIcon />} label="View Dashboard" onClick={() => history.push(`/devices/${params.id}/dashboard`)} showInMenu />,
      ],
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <a
          href={`/devices/${params.row.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {params.row.name}
        </a>
      ),
    },
    {
      field: "battery_level",
      headerName: "Battery Level",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) return "0%";
        else return `${params.value}%`;
      },
    },
    {
      field: "last_seen",
      headerName: "Last Seen",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) return "Never Seen";
      },
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element) => {
        array.push({
          id: element.id,
          name: element.name,
          battery_level: element.battery_level,
          last_seen: element.last_seen,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} tableSize={5} disableFiltering={false} toolbar={true} rowOnClickPath={"/devices/id"} />;
};

export default withRouter(LocationDevicesTable);
