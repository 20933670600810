import React from "react";
import { Col, Card, CardBody, Row } from "reactstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";
import LocationsTable from "./components/locationsTable";
import AddNewLocation from "./components/addNewLocation";
import { getAllLocationsAction } from "./redux/locationActions";

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getAllLocationsAction();
  }

  render() {
    return this.props.isFetching ? (
      <LoadingSpinner loadingText="Loading devices..." />
    ) : (
      <React.Fragment>
        {(this.props.user.role.id === 1 || this.props.user.role.id === 2) && (
          <Row>
            <Col>
              <AddNewLocation />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Card>
              <CardBody>{this.props.locations ? <LocationsTable tableCardData={this.props.locations} /> : "No data."}</CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sites: state.user.sites,
  user: state.user.user,
  locations: state.location.all_locations,
  isFetching: state.location.isFetching,
});

const mapDispatchToProps = {
  getAllLocationsAction: getAllLocationsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
