import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Spinner, Button } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { resetPasswordAction } from "../../redux/userActions";
import ToastifyNotification from "../../../../shared/components/notification/index";

//Icons
import EyeIcon from "mdi-react/EyeIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";

const ResetPasswordForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(null);
  const [formError, setFormError] = useState(null);

  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formValidation = Yup.object().shape({
    password: Yup.string()
      .required("Password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Confirm password is required"),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={formValidation}
        onSubmit={async (values, { resetForm }) => {
          props
            .resetPasswordAction(values)
            .then(() => {
              ToastifyNotification("Password has been changed!");

              //Reset the form and remove any errors
              setFormError(null);
              resetForm();
            })
            .catch((error) => {
              setFormError(error);
            });
        }}
      >
        <Form>
          {formError && <Alert color="danger">{formError}</Alert>}
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Field name="password" type={showPassword ? "text" : "password"} placeholder="Password" autoComplete="off"/>
              <button className={`form__form-group-button${showPassword ? " active" : ""}`} onClick={(e) => togglePassword(e)} type="button">
                <EyeIcon />
              </button>
            </div>
            <div className="form__error_message">
              <ErrorMessage name="password" />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Confirm Password</span>
            <div className="form__form-group-field">
              <Field name="confirmPassword" type={showConfirmPassword ? "text" : "password"} placeholder="Password" autoComplete="off"/>
              <button className={`form__form-group-button${showConfirmPassword ? " active" : ""}`} onClick={(e) => toggleConfirmPassword(e)} type="button">
                <EyeIcon />
              </button>
            </div>
            <div className="form__error_message">
              <ErrorMessage name="confirmPassword" />
            </div>
          </div>
          <Button className="icon" color="success" type="submit">
            {props.isFetching ? (
              <Spinner animation="border" className="btn-loading-spinner" />
            ) : (
              <p>
                <ContentSaveIcon /> Update password
              </p>
            )}
          </Button>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  resetPasswordAction: resetPasswordAction,
};

export default withRouter(connect(null, mapDispatchToProps)(ResetPasswordForm));
