import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, ButtonGroup, Card, CardBody, Alert } from "reactstrap";
import * as Yup from "yup";
import { getDevicesAction, updateDeviceAction } from "../../devices/redux/deviceActions";
import { Form, Formik, Field, ErrorMessage } from "formik";
import ToastifyNotification from "../../../shared/components/notification/index";

//Icons
import PencilIcon from "mdi-react/PencilIcon";
import ContentSaveEditIcon from "mdi-react/ContentSaveEditIcon";
import CloseIcon from "mdi-react/CloseIcon";

class DeviceDetailsForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      editable: false,
      formError: null,
      siteId: null,
    };
  }

  formValidation = () =>
    Yup.object().shape({
      name: Yup.string().min(3, "Device name is too short. Must be 3 characters or more").required("Device name is required"),
      site: Yup.string().required("Site is required"),
      location: Yup.string().required("Location is required"),
      id: Yup.string().required("no device id"),
    });

  toggleFormEdit = () => {
    this.setState({
      editable: !this.state.editable,
    });
  };

  render() {
    return (
      <Card>
        <CardBody>
          <div className="device_details__Device_details_header">
            <div className="card__title">
              <h5 className="bold-text">Device Details</h5>
              <h5 className="subhead">View and update the device details using the edit button</h5>
            </div>
          </div>
          <div className="device_details__device_details">
            <Formik
              initialValues={{
                id: this.props.device.id,
                name: this.props.device.name,
                location: this.props.device.location.id,
                site: this.props.device.site.id,
              }}
              validationSchema={this.formValidation}
              onSubmit={async (values) => {
                // Formik defaults values to strings, api requires int
                values.location = parseInt(values.location);
                values.site = parseInt(values.site);

                this.props
                  .updateDeviceAction(values)
                  .then(() => {
                    this.props.getDevicesAction();
                    ToastifyNotification("Device details have been updated!");
                  })
                  .catch((error) => {
                    this.setState({
                      formError: error.message,
                    });
                  });
              }}
            >
              {({ handleChange, setFieldValue, setErrors, resetForm }) => (
                <Form>
                  {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
                  <div className="form__form-group">
                    <span className="form__form-group-label">Device Name</span>
                    <div className="form__form-group-field">
                      <Field name="name" component="input" type="text" placeholder={this.props.device.name} disabled={!this.state.editable} />
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Site</span>
                    <div className="form__form-group-field">
                      {this.state.editable ? (
                        <React.Fragment>
                          <Field
                            name="site"
                            component="select"
                            onChange={(e) => {
                              //Call default Formik onChange handler
                              handleChange(e);

                              //Update the siteId so we can filter the locations
                              this.setState({ siteId: e.target.value });

                              //Clear the location because a new site must mean a new location as well
                              setFieldValue("location", "");
                            }}
                          >
                            <option></option>
                            {this.props.sites &&
                              this.props.sites.map((site) => (
                                <option key={site.site.id} value={site.site.id}>
                                  {site.site.name}
                                </option>
                              ))}
                          </Field>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Field name="device_site_dummy" component="input" type="text" placeholder={this.props.device.site.name} disabled={true} />
                        </React.Fragment>
                      )}
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="site" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Location</span>
                    <div className="form__form-group-field">
                      {this.state.editable ? (
                        <React.Fragment>
                          <Field name="location" component="select">
                            <option></option>
                            {this.props.sites &&
                              this.props.sites
                                .filter((site) => (this.state.siteId === null ? site.site.id == this.props.device.site.id : site.site.id == this.state.siteId))
                                .map(
                                  (site) =>
                                    site.site.locations.length != 0 &&
                                    site.site.locations.map((location) => (
                                      <option key={location.id} value={location.id}>
                                        {location.name}
                                      </option>
                                    ))
                                )}
                          </Field>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Field name="device_location_dummy" component="input" type="text" placeholder={this.props.device.location.name} disabled={true} />
                        </React.Fragment>
                      )}
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="location" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Device EUI</span>
                    <div className="form__form-group-field">
                      <Field name="deviceEui" component="input" type="text" placeholder={this.props.device.eui} disabled={true} />
                    </div>
                  </div>
                  <div className="form__form_edit_btn_wrapper">
                    {this.state.editable ? (
                      <ButtonGroup>
                        <Button className="icon" color="success" type="submit">
                          <p>
                            <ContentSaveEditIcon /> Save changes
                          </p>
                        </Button>
                        <Button
                          className="icon"
                          color="danger"
                          onClick={() => {
                            this.toggleFormEdit();

                            //Call default Formik handlers
                            setErrors({});
                            resetForm();
                          }}
                        >
                          <p>
                            <CloseIcon /> Cancel
                          </p>
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <Button className="icon" color="primary" onClick={this.toggleFormEdit}>
                        <p>
                          <PencilIcon /> Edit
                        </p>
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.devices.isFetching,
  sites: state.user.sites,
});

const mapDispatchToProps = {
  getDevicesAction: getDevicesAction,
  updateDeviceAction: updateDeviceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsForm);
