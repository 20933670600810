import React from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import PropTypes from "prop-types";

const LoadingSpinner = ({ loadingText }) => {
  return (
    <div className="spinner-container">
      <Card>
        <CardBody>
          <div className="spinner-wrapper">
            <Spinner animation="border" />
            <p>{loadingText}</p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

LoadingSpinner.propTypes = {
  loadingText: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  loadingText: "Loading data...",
};

export default LoadingSpinner;
