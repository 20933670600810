import React from "react";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { connect } from "react-redux";
import { getDevicesAction } from "./redux/deviceActions";
import AddNewDeviceModal from "./components/addNewDeviceModal";
import FilterBar from "../../shared/components/filterBar";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { withRouter } from "react-router";

//Icons
import MenuDownIcon from "mdi-react/MenuDownIcon";
import EyeIcon from "mdi-react/EyeIcon";
import ChartBoxOutlineIcon from "mdi-react/ChartBoxOutlineIcon";

import DataTable from "../../shared/components/table/dataTable";
import { Link } from "react-router-dom";
import DevicesTable from "./components/deviceTable";

class DevicesList extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     filters: {
  //       deviceName: "",
  //       locationName: "Filter location",
  //       locationId: 0,
  //       siteName: "Filter site",
  //       siteId: 0,
  //     },
  //     mobileFilterMenu: false,
  //   };
  // }

  componentDidMount() {
    this.props.getDevicesAction();
  }

  // filterDevicesByName = (e) => {
  //   this.setState({
  //     filters: { ...this.state.filters, deviceName: e.target.value },
  //   });
  // };

  // filterDevicesByLocation = (e) => {
  //   this.setState({
  //     filters: { ...this.state.filters, locationName: e.currentTarget.textContent, locationId: e.target.value },
  //   });
  // };

  // filterDevicesBySite = (e) => {
  //   this.setState({
  //     filters: { ...this.state.filters, siteName: e.currentTarget.textContent, siteId: e.target.value },
  //   });
  // };

  // toggleMobileFilterMenu = () => {
  //   this.setState({
  //     mobileFilterMenu: !this.state.mobileFilterMenu,
  //   });
  // };

  render() {
    // const tableData = this.props.devices.activeDevices
    //   .filter((device) => device.name.toLowerCase().includes(this.state.filters.deviceName.toLowerCase()))
    //   .filter((device) => (this.state.filters.locationId == 0 ? true : device.location.id == this.state.filters.locationId))
    //   .filter((device) => (this.state.filters.siteId == 0 ? true : device.site.id == this.state.filters.siteId));

    return this.props.isFetching ? (
      <LoadingSpinner loadingText="Loading devices..." />
    ) : (
      <React.Fragment>
        {(this.props.user.role.id === 1 || this.props.user.role.id === 2) && (
          <Row>
            <Col>
              <AddNewDeviceModal />
            </Col>
          </Row>
        )}
        {/* <Row>
          <Col lg="12" md="12" sm="12">
            <div className="filter_bar mobile">
              <Card>
                <CardBody className="filter_bar__card_body">
                  <div className="filter_bar__mobile_filter_menu_toggle">
                    <span onClick={this.toggleMobileFilterMenu}>
                      Filter devices
                      <MenuDownIcon />
                    </span>
                  </div>
                  <Collapse className="filter_bar__mobile_filter_menu" isOpen={this.state.mobileFilterMenu}>
                    <FilterBar
                      filters={this.state.filters}
                      filterDevicesByNameFunc={this.filterDevicesByName}
                      filterDevicesByLocationFunc={this.filterDevicesByLocation}
                      filterDevicesBySiteFunc={this.filterDevicesBySite}
                    />
                  </Collapse>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg="12" md="12" sm="12">
            <div className="filter_bar desktop">
              <Card>
                <CardBody className="filter_bar__card_body">
                  <FilterBar
                    filters={this.state.filters}
                    filterDevicesByNameFunc={this.filterDevicesByName}
                    filterDevicesByLocationFunc={this.filterDevicesByLocation}
                    filterDevicesBySiteFunc={this.filterDevicesBySite}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Card>
              <CardBody>
                <DevicesTable tableCardData={this.props.devices.activeDevices} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.devices.isFetching,
  devices: state.devices,
  user: state.user.user,
});

const mapDispatchToProps = {
  getDevicesAction: getDevicesAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DevicesList));
