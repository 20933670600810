import React from "react";
import { Button } from "reactstrap";
import DataTable from "../../../../shared/components/table/dataTable";
import { withRouter } from "react-router-dom";

const UserTable = ({ tableCardData, history }) => {
  const tableHeaders = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return `${params.getValue(params.id, "first_name") || ""} ${params.getValue(params.id, "last_name") || ""}`;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "active",
      headerName: "Active",
      type: "boolean",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "created",
      headerName: "Created",
      type: "dateTime",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      type: "actions",
      renderCell: (params) => (
        <div className="material_data_table__cell_btn">
          <Button
            className="icon btn btn-small"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`/admin/user/${params.row.id}`);
            }}
          >
            Edit user
          </Button>
        </div>
      ),
      flex: 1,
      minWidth: 150,
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element) => {
        array.push({
          id: element.id,
          email: element.email,
          first_name: element.firstname,
          last_name: element.surname,
          active: element.active,
          created: element.created,
          role: element.role.role,
        });
      });
    }
    return array;
  };

  return (
    <DataTable
      columns={tableHeaders}
      rows={createData(tableCardData)}
      tableSize={10}
      disableFiltering={false}
      toolbar={true}
      showToolbar={true}
      rowOnClickPath={"/admin/user/id"}
    />
  );
};

export default withRouter(UserTable);
