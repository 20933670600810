import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import DevicesList from "../containers/devices";

const DevicesPage = () => {
  const orgName = useSelector((state) => state.user.organisation.name);

  return (
    <Container className="dashboard">
      <Row>
        <Col lg="12" md="12" sm="12">
          <h3 className="page-title">Devices</h3>
          <h3 className="page-subhead subhead">Listed below is all of your devices associated with {orgName}. Filter the list by name, site and location.</h3>
        </Col>
      </Row>
      <DevicesList />
    </Container>
  );
};

export default DevicesPage;
