import React from "react";
import { Col, Card, CardBody, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import { deleteUserAction, getOrganisationsAction, getUserSitePermissionsAction } from "../redux/adminActions";
import Ava from "../../../assets/img/profile-avatar.png";
import ResetUserPasswordForm from "./components/ResetUserPasswordForm";
import UsersPermissionsForm from "./components/usersPermissionsForm";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import ModalComponent from "../../../shared/components/modal/modal";
import ToastifyNotification from "../../../shared/components/notification";
import { withRouter } from "react-router";
import LoadingSpinner from "../../../shared/components/spinner/loading_spinner";
import UserInformation from "./components/userInformation";

class UserDetails extends React.Component {
  constructor(props) {
    super();
    this.state = {
      deleteModal: false,
    };
  }

  componentDidMount() {
    this.props.getUserSitePermissionsAction({ user_id: this.props.user_id });
  }

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  deleteUser = () => {
    this.props.deleteUserAction({ user_id: this.props.user_id }).then(() => {
      ToastifyNotification("User has been deleted");
      this.props.history.push("/admin/users");
    });
  };

  render() {
    const user = this.props.users.filter((user) => user.id == this.props.user_id)[0];

    return this.props.isFetching ? null : user ? (
      <React.Fragment>
        <Row>
          <Col lg="4" md="12" sm="12">
            <Card>
              <CardBody className="profile__card">
                <div className="profile__information">
                  <div className="profile__avatar">
                    <img src={Ava} alt="avatar" />
                  </div>
                  <div className="profile__data">
                    <p className="profile__name">{user.firstname + " " + user.surname}</p>
                    <p className="profile__company">{user.organisation.name}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8" md="12" sm="12">
            <Card>
              <CardBody>
                <UserInformation user={user} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="12" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Change user password</h5>
                  <h5 className="subhead">
                    This form can be used to change the password of a user. If a users password is changed remind them to update it themselves using the password change tool under
                    the profile page.
                  </h5>
                </div>
                <ResetUserPasswordForm user={user.id} />
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="12" sm="12">
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Change User Permissions</h5>
                  <h5 className="subhead">
                    Change users permissions to view sites and the devices at those locations. After a permission change the user will have to log out and back in for new
                    permission to update.
                  </h5>
                </div>
                <UsersPermissionsForm user={user.id} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Button className="icon" color="danger" onClick={this.toggleDeleteModal}>
                  <p>
                    <CloseCircleIcon />
                    Delete User
                  </p>
                </Button>
                <ModalComponent
                  title={"Are you sure?"}
                  children={
                    <p>
                      By clicking on the ok button you confirm that you want to delete this user. <strong>This action can not be undone.</strong>
                    </p>
                  }
                  modal={this.state.deleteModal}
                  onClick={this.deleteUser}
                  toggle={this.toggleDeleteModal}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      <LoadingSpinner loadingText="Loading user information" />
    );
  }
}

const mapDispatchToProps = {
  getOrganisationsAction: getOrganisationsAction,
  deleteUserAction: deleteUserAction,
  getUserSitePermissionsAction: getUserSitePermissionsAction,
};

const mapStateToProps = (state) => ({
  users: state.admin.users,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));
