import React from "react";
import { Card, CardBody, Col, UncontrolledTooltip } from "reactstrap";
import InformationIcon from "mdi-react/InformationIcon";
import PropTypes from "prop-types";
import LineChart from "../../../components/charts/lineChart";

const SensorLineChart = ({ chartTitle, chartData, cardId, toolTipString, chartColors, strapDimensions, unit, cardIcon }) => {
  const currentValue = chartData.data;
  return (
    <Card>
      <CardBody className="linechart_card">
        <div className="linechart_card__header">
          <div className="linechart_card__title">
          {cardIcon}
            <h5 className="bold-text">{chartTitle}</h5>
          </div>
          <div className="linechart_card__info_btn">
            <InformationIcon id={cardId} />
            <UncontrolledTooltip placement="bottom" target={cardId}>
              {toolTipString}
            </UncontrolledTooltip>
          </div>
        </div>
        <div className="linechart__current_value_wrapper">
            <p className="linechart__current_value">
              {currentValue[currentValue.length - 1][1]}
              <span className="linechart__current_unit">{unit}</span>
            </p>
          </div>
        <div className="chartWrapper">
          <LineChart chartColors={chartColors} chartData={Object.values([chartData])} />
        </div>
      </CardBody>
    </Card>
  );
};

SensorLineChart.propTypes = {
  chartTitle: PropTypes.string.isRequired,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array.isRequired,
  toolTipString: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
};

export default SensorLineChart;
