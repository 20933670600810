import React from "react";
import { Progress, UncontrolledTooltip } from "reactstrap";
import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import ExclamationThickIcon from "mdi-react/ExclamationThickIcon";
import classNames from "classnames";
import { Link } from "react-router-dom";

const environment_variable_meta = {
  CO2: {
    unit: "ppm",
    threshold: 1000,
  },
  Temperature: {
    unit: "°C",
    threshold: 25,
  },
  TVOC: {
    unit: "ppb",
    threshold: 2000,
  },
  Humidity: {
    unit: "%",
    threshold: 70,
  },
  "Activity Level": {
    unit: "Triggers",
    threshold: 1500,
  },
  Illumination: {
    unit: "LUX",
    threshold: 300,
  },
};

const LocationPerformance = (props) => {
  //Get location either worst or best
  var location = [props.worst ? props.data[0] : props.best ? props.data[props.data.length - 1] : null];

  var type = props.worst ? "worst" : props.best ? "best" : null;

  return (
    <React.Fragment>
      <div className="site__location_performance">
        <div className="site__location_performance_location_card">
          <span className="location_name">
            <Link
              to={{
                pathname: `/location/${location[0].id}`,
              }}
            >
              {location[0].name}
            </Link>
          </span>
        </div>
      </div>
      <div className="site__location_performance_graph">
        {location[0].measurements.map((measurement, index) => {
          return (
            <div key={index} className="site__location_performance_graph-item">
              <div
                className={classNames("site__location_performance_graph-title", {
                  location_performance_alert: Math.round(measurement.value) > environment_variable_meta[measurement.name]["threshold"],
                })}
              >
                {Math.round(measurement.value) > environment_variable_meta[measurement.name]["threshold"] ? (
                  <div>
                    <ExclamationThickIcon id={type + "_" + measurement.name + "_alert"} />
                    <UncontrolledTooltip placement="bottom" target={type + "_" + measurement.name + "_alert"}>
                      {measurement.name} has exceeded the {environment_variable_meta[measurement.name]["threshold"]}
                      {environment_variable_meta[measurement.name]["unit"]} threshold.
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  <ExclamationThickIcon />
                )}
                {measurement.name}
              </div>
              <div className="site__location_performance_graph-progress">
                <div className="progress-wrap progress-wrap--small progress-wrap--blue-gradient progress-wrap--rounded">
                  <p className="progress__label">
                    {Math.round(measurement.value)}
                    {environment_variable_meta[measurement.name]["unit"]}
                  </p>
                  <Progress value={Math.round(measurement.value)} max={environment_variable_meta[measurement.name]["threshold"]} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default LocationPerformance;
