import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Spinner, Alert } from "reactstrap";
import { getUserOrganisationAction } from "../../user/redux/userActions";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import ToastifyNotification from "../../../shared/components/notification";
import * as Yup from "yup";

//Icons
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import { createSiteRequestAction } from "../../admin/redux/adminActions";
import { getAllSitesAction } from "../redux/siteActions";

const AddNewSite = (props) => {
  //Local State
  const [modal, setModal] = useState(false);
  const [formError, setFormError] = useState(null);

  //Toggle modal visibility
  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const formValidation = Yup.object().shape({
    site_name: Yup.string().min(3, "Name of the site must be at least 3 characters").required("Site is required"),
  });

  return (
    <React.Fragment>
      <Button className="icon btn" color="success" onClick={toggle}>
        <p>
          <PlusCircleIcon />
          Add Site
        </p>
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog">
        <div className="card__title">
          <h5 className="bold-text">Add a new site</h5>
          <h5 className="subhead">Fill out the details below to add a new site.</h5>
        </div>
        <Formik
          initialValues={{
            site_name: "",
          }}
          validationSchema={formValidation}
          onSubmit={async (values, { resetForm }) => {
            props
              .createSiteRequestAction(values)
              .then(() => {
                new Promise.all([props.getUserOrganisationAction(), props.getAllSitesAction()])
                  .then(() => {
                    ToastifyNotification("New site successfully added");
                    //Reset the form and remove any errors
                    resetForm({});
                    setFormError(null);
                    setModal(false);
                  })
                  .catch((error) => {
                    setFormError(error.message);
                  });
              })
              .catch((error) => {
                setFormError(error.message);
              });
          }}
        >
          {({ handleChange }) => (
            <Form>
              {formError && <Alert color="danger">{formError}</Alert>}
              <div className="form__form-group">
                <span className="form__form-group-label">Site</span>
                <div className="form__form-group-field">
                  <Field name="site_name" type="text" placeholder="Please enter a new site name" />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="site_name" />
                </div>
              </div>
              <Button className="icon" color="success" type="submit">
                {props.isFetching ? (
                  <Spinner animation="border" className="btn-loading-spinner" />
                ) : (
                  <p>
                    <ContentSaveIcon /> Add Site
                  </p>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserOrganisationAction: getUserOrganisationAction,
  createSiteRequestAction: createSiteRequestAction,
  getAllSitesAction: getAllSitesAction,
};

const mapStateToProps = (state) => ({
  sites: state.user.sites,
  isFetching: state.site.isFetching,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewSite));
