import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TopBarSidebarButton from "./TopBarSidebarButton";
import TopBarProfile from "./TopbarProfile";

class TopBar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div className="topBar">
        <div className="topBar__wrapper">
          <div className="topBar__left">
            <TopBarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} changeSidebarVisibility={changeSidebarVisibility} />
            <Link className="topBar__logo" to="/devices" />
          </div>
          <div className="topBar__right">
            <TopBarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
