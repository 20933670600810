import { handleActions } from "redux-actions";
import {
  createReportFailure,
  createReportRequest,
  createReportSuccess,
  getReportSuccess,
  getReportRequest,
  getReportFailure,
  runReportRequest,
  runReportSuccess,
  runReportFailure,
  getAllReportRunsRequest,
  getAllReportRunsSuccess,
  getAllReportRunsFailure,
  deleteReportRunRequest,
  deleteReportRunSuccess,
  deleteReportRunFailure,
  deleteReportRequest,
  deleteReportSuccess,
  deleteReportFailure,
} from "./reportActions";

const defaultState = {
  reports: [],
  reportRuns: [],
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [createReportRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [createReportSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [createReportFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [getReportRequest](state) {
      return {
        ...state,
        reports: null,
        isFetching: true,
        error: null,
      };
    },
    [getReportSuccess](state, { payload }) {
      return {
        ...state,
        reports: payload,
        isFetching: false,
        error: null,
      };
    },
    [getReportFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [runReportRequest](state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [runReportSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [runReportFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [getAllReportRunsRequest](state) {
      return {
        ...state,
        reportRuns: null,
        isFetching: true,
        error: null,
      };
    },
    [getAllReportRunsSuccess](state, { payload }) {
      return {
        ...state,
        reportRuns: payload,
        isFetching: false,
        error: null,
      };
    },
    [getAllReportRunsFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [deleteReportRunRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [deleteReportRunSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [deleteReportRunFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [deleteReportRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [deleteReportSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [deleteReportFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
