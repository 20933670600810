import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Spinner, Alert } from "reactstrap";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import PropTypes from "prop-types";
import { ErrorMessage, Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { enrollDeviceAction } from "../../../admin/redux/adminActions";
import { getDevicesAction } from "../../../devices/redux/deviceActions";
import ToastifyNotification from "../../../../shared/components/notification/index";

const EnrollDeviceForm = ({ organisations, deviceModels, isFetching, enrollDeviceAction, getDevicesAction }) => {
  const [formError, setFormError] = useState(null);

  const formValidation = Yup.object().shape({
    organisation: Yup.string().required("Organisation name is required."),
    eui: Yup.string()
      .trim()
      .min(16, "EUI must be 16 characters long")
      .matches(/^([0-9aA-fF\:\-])/, "Invalid EUI")
      .required("Device EUI is required"),
    model: Yup.string().required("Device model is required"),
  });

  return (
    <Formik
      initialValues={{
        organisation: "",
        eui: "",
        model: "",
      }}
      validationSchema={formValidation}
      onSubmit={async (values, { resetForm }) => {
        //Transform EUI to uppercase for consistency
        values.eui = values.eui.toUpperCase();

        enrollDeviceAction(values)
          .then(() => {
            ToastifyNotification("New device successfully enrolled!");

            //Reset the form and remove any errors
            setFormError(null);
            resetForm();

            //Update device list
            getDevicesAction().catch((error) => {
              console.log(error);
            });
          })
          .catch((error) => {
            setFormError(error.message);
          });
      }}
    >
      <Form>
        {formError && <Alert color="danger">{formError}</Alert>}
        <div className="form__form-group">
          <span className="form__form-group-label">Organisation Name</span>
          <div className="form__form-group-field">
            <Field name="organisation" as="select">
              <option></option>
              {organisations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Field>
          </div>
          <div className="form__error_message">
            <ErrorMessage name="organisation" />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Device EUI</span>
          <div className="form__form-group-field">
            <Field name="eui" type="text" />
          </div>
          <div className="form__error_message">
            <ErrorMessage name="eui" />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Device model</span>
          <div className="form__form-group-field">
            <Field name="model" as="select">
              <option></option>
              {deviceModels.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.name}
                </option>
              ))}
            </Field>
          </div>
          <div className="form__error_message">
            <ErrorMessage name="model" />
          </div>
        </div>
        <Button className="icon" color="success" type="submit">
          {isFetching ? (
            <Spinner animation="border" className="btn-loading-spinner" />
          ) : (
            <p>
              <ContentSaveIcon /> Add
            </p>
          )}
        </Button>
      </Form>
    </Formik>
  );
};

EnrollDeviceForm.propTypes = {
  organisations: PropTypes.array.isRequired,
  deviceModels: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: state.admin.isFetching,
});

const mapDispatchToProps = {
  enrollDeviceAction: enrollDeviceAction,
  getDevicesAction: getDevicesAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnrollDeviceForm));
