import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import PropTypes from "prop-types";

const BasicTable = ({ tableCardTitle, tableCardSubtitle, tableCardData }) => {
  const tableHeaders = [
    "Timestamp",
    ...new Set(
      Object.values(tableCardData)
        .map((item) => Object.keys(item))
        .flat()
    ),
  ];

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{tableCardTitle}</h5>
            <h5 className="subhead">{tableCardSubtitle}</h5>
          </div>
          {Object.keys(tableCardData) != 0 ? (
            <Table responsive hover>
              <thead>
                <tr>
                  {tableHeaders.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(tableCardData)
                  .reverse()
                  .map((item, index) => (
                    <tr key={index}>
                      <td key={index}>{item[0]}</td>
                      {tableHeaders.slice(1).map((item2, index) => (
                        <td key={index}>{item[1][item2]}</td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <>No measurements exist.</>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

BasicTable.propTypes = {
  tableCardTitle: PropTypes.string.isRequired,
  tableCardSubtitle: PropTypes.string.isRequired,
  tableCardData: PropTypes.object.isRequired,
};

BasicTable.defaultProps = {
  tableCardTitle: "",
  tableCardSubtitle: "",
  tableCardData: {},
};

export default BasicTable;
