import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function AdminRoute({ component: Component, ...rest }) {
  const isAuth = useSelector((state) => state.user.isAuthenticated);
  const isAdmin = useSelector((state) => state.user.user.role.id);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAdmin === 1 || isAdmin === 2) {
          return <Component />;
        } else if (isAuth) {
          return <Redirect to={{ pathname: "/user", state: { from: props.location } }} />;
        } else {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
      }}
    />
  );
}

export default AdminRoute;
