import React from "react";
import { Col, Card, CardBody, Row, Spinner } from "reactstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";
import LocationDetailsForm from "./components/locationDetailsForm";
import LocationDevices from "./components/locationDevicesTable";
import { getLocationAction, getLocationDevicesAction } from "../locations/redux/locationActions";

class LocationOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getLocationAction({ location_id: this.props.locationId });
    this.props.getLocationDevicesAction({ location_id: this.props.locationId });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isFetching ? (
          <LoadingSpinner loadingText="Loading devices..." />
        ) : (
          <React.Fragment>
            <Row>
              <Col lg="6" md="12" sm="12">
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Location Devices</h5>
                      <h5 className="subhead">Listed below are all the devices associated with this location</h5>
                    </div>
                    {this.props.location.devices ? (
                      <React.Fragment>
                        <div className="location_overview__total_devices">
                          <p>
                            <span>Total Devices: </span>
                            {this.props.location.devices.length}
                          </p>
                        </div>
                        <LocationDevices tableCardData={this.props.location.devices} />
                      </React.Fragment>
                    ) : (
                      <Spinner />
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="12" sm="12">
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Location Details</h5>
                      <h5 className="subhead">View and update the location details below</h5>
                    </div>
                    {this.props.location.location ? <LocationDetailsForm /> : <Spinner />}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sites: state.user.sites,
  location: state.location,
  isFetching: state.location.isFetching,
});

const mapDispatchToProps = {
  getLocationDevicesAction: getLocationDevicesAction,
  getLocationAction: getLocationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationOverview);
