import { handleActions } from "redux-actions";
import {
  getDevicesFailure,
  getDevicesRequest,
  getDevicesSuccess,
  activateDeviceRequest,
  activateDeviceSuccess,
  activateDeviceFailure,
  updateDeviceFailure,
  updateDeviceSuccess,
  updateDeviceRequest,
  getDeviceMeasurementsFailure,
  getDeviceMeasurementsSuccess,
  getDeviceMeasurementsDetailsSuccess,
  getDeviceMeasurementsRequest,
  getDeviceLocationsRequest,
  getDeviceLocationsSuccess,
  getDeviceLocationsFailure,
} from "./deviceActions";

const defaultState = {
  activeDevices: [],
  inactiveDevices: [],
  isFetching: false,
  error: null,
  measurements: null,
  measurementsDetails: null,
};

export default handleActions(
  {
    [getDevicesRequest](state) {
      return {
        ...state,
        isFetching: true,
        inactiveDevices: [],
        activeDevices: [],
        error: null,
      };
    },
    [getDevicesSuccess](state, { payload }) {
      return {
        ...state,
        inactiveDevices: payload.filter((item) => item.active === false),
        activeDevices: payload.filter((item) => item.active === true),
        isFetching: false,
        error: null,
      };
    },
    [getDevicesFailure](state, { payload }) {
      return {
        ...state,
        inactiveDevices: [],
        activeDevices: [],
        isFetching: false,
        error: payload,
      };
    },
    // Activate Device reducers
    [activateDeviceRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [activateDeviceSuccess](state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [activateDeviceFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    // Update  Device reducers
    [updateDeviceRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateDeviceSuccess](state, { payload }) {
      return {
        ...state,
        deviceList: Array.isArray(payload.devices) ? payload.devices : [payload.devices],
        isFetching: false,
        error: null,
      };
    },
    [updateDeviceFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [getDeviceMeasurementsRequest](state) {
      return {
        ...state,
        isFetching: true,
        measurements: null,
        error: null,
      };
    },
    [getDeviceMeasurementsSuccess](state, { payload }) {
      return {
        ...state,
        measurements: payload.measurements,
        isFetching: false,
        error: null,
      };
    },
    [getDeviceMeasurementsDetailsSuccess](state, { payload }) {
      return {
        ...state,
        measurementDetails: payload.measurements,
        isFetching: false,
        error: null,
      };
    },
    [getDeviceMeasurementsFailure](state, { payload }) {
      return {
        ...state,
        measurements: null,
        isFetching: false,
        error: payload,
      };
    },
    [getDeviceLocationsRequest](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [getDeviceLocationsSuccess](state, { payload }) {
      return {
        ...state,
        locations: payload.locations,
        isFetching: false,
        error: null,
      };
    },
    [getDeviceLocationsFailure](state, { payload }) {
      return {
        ...state,
        locations: null,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
