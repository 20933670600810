import React from "react";
import { Col, Container, Row } from "reactstrap";
import EnrollDeviceFrom from "../../containers/admin/enrollNewDevice";

const EnrollDeviceView = () => (
  <Container className="dashboard">
    <Row>
      <Col lg="12" md="12" sm="12">
        <h3 className="page-title">Platform Administration</h3>
        <h3 className="page-subhead subhead">ROLE: ADMIN</h3>
      </Col>
    </Row>
    <Row>
      <EnrollDeviceFrom />
    </Row>
  </Container>
);

export default EnrollDeviceView;
