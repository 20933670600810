import React from "react";
import DownloadIcon from "mdi-react/DownloadIcon";
import { connect } from "react-redux";
import api from "../../../services/apiClient";
import ToastifyNotification from "../../../shared/components/notification";
import { deleteReportRunAction, getAllReportRunsAction } from "../../reports/redux/reportActions";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "mdi-react/DeleteIcon";
import DataTable from "../../../shared/components/table/dataTable";
import Status from "../../../shared/components/table/dataTable/components/tableChip";

//Util function to download file via a html element
function dataToFile(data, filename) {
  const blob = new Blob([data], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = filename;
  link.href = url;
  link.click();
}

const ReportRunTable = (props) => {
  const tableHeaders = [
    {
      field: "id",
      headerName: "Run ID",
      minWidth: 150,
    },
    {
      field: "report_date_range",
      headerName: "Report Date Range",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "report_status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <Status status={params.row.report_status} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 10,
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} label="Delete Report Run" onClick={() => deleteReportRun(params.id)} />,
        <GridActionsCellItem icon={<DownloadIcon />} label="CSV Download" onClick={() => handleCsvDownload(params.id)} showInMenu />,
        <GridActionsCellItem icon={<DownloadIcon />} label="JSON Download" onClick={() => handleJsonDownload(params.id)} showInMenu />,
      ],
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element, index) => {
        array.push({
          id: element.id,
          report_date_range: element.report_start_date + " - " + element.report_end_date,
          report_status: element.report_status,
        });
      });
    }
    return array;
  };

  const handleCsvDownload = async (report_run_id) => {
    //Fetch report from Azure blob via the api
    var response = await api.downloadReport.get({ report_run_id: report_run_id });

    //Get data formatted in JSON
    const items = response.data;

    //Convert JSON to CSV
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")),
    ].join("\r\n");

    //Download data
    dataToFile(csv, "iot_data_download.csv");
  };

  const handleJsonDownload = async (report_run_id) => {
    //Fetch report from Azure blob via the api
    var response = await api.downloadReport.get({ report_run_id: report_run_id });

    //Write JSON data to a blob so it can be downloaded
    const json = JSON.stringify(response.data);

    //Download data
    dataToFile(json, "iot_data_download.json");
  };

  const deleteReportRun = (report_run_id) => {
    props
      .deleteReportRunAction({ report_run_id: report_run_id })
      .then(() => {
        props.getAllReportRunsAction({ report_id: props.reportId });
        ToastifyNotification("Report run deleted");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return <DataTable columns={tableHeaders} rows={createData(props.tableCardData)} tableSize={10} disableFiltering={true} showToolbar={false} />;
};

const mapStateToProps = (state) => ({
  isFetching: state.reports.isFetching,
});

const mapDispatchToProps = {
  deleteReportRunAction: deleteReportRunAction,
  getAllReportRunsAction: getAllReportRunsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportRunTable);
