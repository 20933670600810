import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import LocationOverview from "../containers/locationOverview";
import { getLocationAction } from "../containers/locations/redux/locationActions";

const LocationOverviewPage = (props) => {
  let { id } = useParams();

  //This returns a array of arrays of objects
  const locations = props.sites.map((site) => site.site.locations.filter((location) => location.id == id));

  const formattedLocation = [];

  //Loops though and push all locations into one array
  locations.forEach((element) => {
    if (element) {
      element.map((e) => formattedLocation.push(e));
    }
  });

  const location = formattedLocation.pop();

  return (
    <Container className="dashboard">
      <Row>
        <Col lg="12" md="12" sm="12">
          <h3 className="page-title">{location.name} Overview</h3>
          <h3 className="page-subhead subhead">View and update location details here</h3>
        </Col>
      </Row>
      <LocationOverview locationId={id} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
sites: state.user.sites
});

const mapDispatchToProps = ({
  getLocationAction: getLocationAction
})

export default connect(mapStateToProps, mapDispatchToProps)(LocationOverviewPage);
