import React from "react";
import { Row, Col, Card, CardBody, Spinner, Collapse } from "reactstrap";
import HeatmapGraph from "../../../shared/components/charts/heatmap";
import { connect } from "react-redux";
import { getAggregatedData24hrsAction } from "../redux/dashboardActions";
import { environment_variable_meta } from "../../../config/config";
import FilterBar from "../../../shared/components/filterBar";

//icons
import MenuDownIcon from "mdi-react/MenuDownIcon";

class Heatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        measurement: "CO2",
        measurement_unit: environment_variable_meta["CO2"].unit,
        measurement_threshold: environment_variable_meta["CO2"].threshold,
        siteName: this.props.userSites[0] ? this.props.userSites[0].site.name : "-- All --",
        siteId: this.props.userSites[0] ? this.props.userSites[0].site.id : 0,
      },
      mobileFilterMenu: false,
    };
  }

  componentDidMount() {
    this.props.getAggregatedData24hrsAction({ name: this.state.filters.measurement, site_id: this.state.filters.siteId }).catch((err) => {
      console.log(err);
    });
  }

  filterByMeasurement = (e) => {
    if (this.state.filters.measurement != e.target.value) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            measurement: e.target.value,
            measurement_unit: environment_variable_meta[e.target.value].unit,
            measurement_threshold: environment_variable_meta[e.target.value].threshold,
          },
        },
        () => {
          this.props.getAggregatedData24hrsAction({ name: this.state.filters.measurement, site_id: this.state.filters.siteId }).catch((err) => {
            console.log(err);
          });
        }
      );
    }
  };

  filterBySite = (e) => {
    if (this.state.filters.siteId != e.target.value) {
      this.setState(
        {
          filters: { ...this.state.filters, siteName: e.currentTarget.textContent, siteId: e.target.value },
        },
        () => {
          this.props.getAggregatedData24hrsAction({ name: this.state.filters.measurement, site_id: this.state.filters.siteId }).catch((err) => {
            console.log(err);
          });
        }
      );
    }
  };

  toggleMobileFilterMenu = () => {
    this.setState({
      mobileFilterMenu: !this.state.mobileFilterMenu,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className="filter_bar mobile">
              <Card>
                <CardBody className="filter_bar__card_body">
                  <div className="filter_bar__mobile_filter_menu_toggle">
                    <span onClick={this.toggleMobileFilterMenu}>
                      Filter measurements
                      <MenuDownIcon />
                    </span>
                  </div>
                  <Collapse className="filter_bar__mobile_filter_menu" isOpen={this.state.mobileFilterMenu}>
                    <FilterBar
                      filters={this.state.filters}
                      filterDevicesByMeasurementFunc={this.filterByMeasurement}
                      filterDevicesBySiteFunc={this.filterBySite}
                      measurementHeaders={["CO2", "Temperature", "Humidity", "TVOC", "Activity Level", "Illumination"]}
                    />
                  </Collapse>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg="12" md="12" sm="12">
            <div className="filter_bar desktop">
              <Card>
                <CardBody className="filter_bar__card_body">
                  <FilterBar
                    filters={this.state.filters}
                    filterDevicesByMeasurementFunc={this.filterByMeasurement}
                    filterDevicesBySiteFunc={this.filterBySite}
                    measurementHeaders={["CO2", "Temperature", "Humidity", "TVOC", "Activity Level", "Illumination"]}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody style={{ overflowX: "auto" }}>
                <div className="card__title">
                  <h5 className="bold-text">{`${this.state.filters.measurement} telemetry`}</h5>
                  <h5 className="subhead">{`The ${this.state.filters.measurement} values are hourly averages shown in ${this.state.filters.measurement_unit}.`}</h5>
                </div>
                {this.props.isFetching ? (
                  <Spinner />
                ) : this.props.aggregatedData24hrs ? (
                  <HeatmapGraph chartData={this.props.aggregatedData24hrs} environmentVariableThreshold={this.state.filters.measurement_threshold} />
                ) : (
                  "No data"
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.dashboards.isFetching,
  aggregatedData24hrs: state.dashboards.aggregatedData24hrs,
  userSites: state.user.sites,
});

const mapDispatchToProps = {
  getAggregatedData24hrsAction: getAggregatedData24hrsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Heatmap);
