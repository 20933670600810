import React, { PureComponent } from "react";
import { Modal, Button, Spinner, Alert } from "reactstrap";
import { connect } from "react-redux";
import { enrollUserAction, getRolesAction, getUsersAction } from "../../redux/adminActions";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import ToastifyNotification from "../../../../shared/components/notification/index";

//Icons
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import MultiSelect from "../../../../shared/components/multiSelect/multiSelect";

class AddNewUserModel extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      showRepeatPassword: false,
      formError: null,
      modal: false,
      allSites: false,
    };
  }

  componentDidMount() {
    this.props.getRolesAction();
  }

  formValidation = () =>
    Yup.object().shape({
      organisation: Yup.string().required("Organisation is required"),
      role: Yup.string().required("User role is required"),
      email: Yup.string().email("Please enter a valid email").required("Email is required"),
      firstname: Yup.string().min(3, "First name to short. Must be 3 characters or more").required("First name is required"),
      surname: Yup.string().min(3, "Last name to short. Must be 3 characters or more").required("Last name is required"),
      sites: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
          })
        )
        .min(1, "Please select the site(s)")
        .required("Please select the site(s)"),
    });

  //Toggle modal visibility
  toggle = () => {
    //Reset the form to default
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleAllSitesOptions = (e, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue("sites", [{ allSites: e.target.checked }]);
    } else {
      setFieldValue("sites", "");
    }
    this.setState({
      allSites: !this.state.allSites,
      deviceInputDisabled: !this.state.deviceInputDisabled,
    });
  };

  render() {
    //Sites dropdown items
    const sites = this.props.sites.map((site) => ({ value: site.site.id, label: site.site.name }));

    return (
      <React.Fragment>
        <Button className="icon btn" color="success" onClick={this.toggle}>
          <p>
            <PlusCircleIcon />
            Add user
          </p>
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog">
          <div className="card__title">
            <h5 className="bold-text">Add a new user</h5>
            <h5 className="subhead">Fill out the user details below and click add. The user will then receive an email to complete the account setup</h5>
          </div>
          <Formik
            initialValues={{
              role: "",
              email: "",
              firstname: "",
              surname: "",
              organisation: this.props.user.organisation.id,
              url: window.location.origin,
              sites: "",
              allSites: false,
            }}
            validationSchema={this.formValidation}
            onSubmit={async (values) => {
              this.props
                .enrollUserAction(values)
                .then(() => {
                  ToastifyNotification("User enrolled. The user will now receive a email to complete the account set up.");
                  this.props.getUsersAction().then(() => {
                    this.setState((prevState) => ({
                      modal: !prevState.modal,
                    }));
                  });
                })
                .catch((error) => {
                  this.setState({
                    formError: error.message,
                  });
                });
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <Field name="email" type="email" />
                  </div>
                  <div className="form__error_message">
                    <ErrorMessage name="email" />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">First Name</span>
                  <div className="form__form-group-field">
                    <Field name="firstname" type="text" />
                  </div>
                  <div className="form__error_message">
                    <ErrorMessage name="firstname" />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Last Name</span>
                  <div className="form__form-group-field">
                    <Field name="surname" type="text" />
                  </div>
                  <div className="form__error_message">
                    <ErrorMessage name="surname" />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">User Role</span>
                  <div className="form__form-group-field">
                    <Field name="role" as="select">
                      <option></option>
                      {this.props.user.role.id === 1
                        ? this.props.roles &&
                          this.props.roles.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.role}
                            </option>
                          ))
                        : this.props.roles &&
                          this.props.roles
                            .filter((role) => role.id !== 1)
                            .map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.role}
                              </option>
                            ))}
                    </Field>
                  </div>
                  <div className="form__error_message">
                    <ErrorMessage name="role" />
                  </div>
                </div>
                <div className="form__form-group">
                  <p className="form__form-group-label">Select the sites the user should have access to</p>
                  <div className="form__form-group-field_multiSelect">
                    <MultiSelect name="sites" options={sites} onChange={setFieldValue} disabled={this.state.allSites} />
                  </div>
                  <div className="form__form-group_checkbox">
                    <span>Include all sites?</span>
                    <Field
                      className="checkbox"
                      type="checkbox"
                      name="allSites"
                      onClick={(e) => {
                        this.toggleAllSitesOptions(e, setFieldValue);
                      }}
                    />
                  </div>
                  <div className="form__error_message">
                    <ErrorMessage name="sites" />
                  </div>
                </div>
                <Button className="icon" color="success" type="submit">
                  {this.props.isFetching ? (
                    <Spinner animation="border" className="btn-loading-spinner" />
                  ) : (
                    <p>
                      <ContentSaveIcon /> Add User
                    </p>
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  roles: state.admin.roleList,
  isFetching: state.admin.isFetching,
  sites: state.user.sites,
});

const mapDispatchToProps = {
  enrollUserAction: enrollUserAction,
  getRolesAction: getRolesAction,
  getUsersAction: getUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserModel);
