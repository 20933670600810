import React, { Component } from "react";
import { Collapse } from "reactstrap";
import classNames from "classnames";

class SidebarCategory extends Component {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { title, icon, isNew, children } = this.props;
    const { collapse } = this.state;
    const categoryClass = classNames({
      "sidebar__category-wrap": true,
      "sidebar__category-wrap--open": collapse,
    });

    return (
      <div className={categoryClass}>
        <button type="button" className="sidebar__link sidebar__category" onClick={this.toggle}>
          {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ""}
          <p className="sidebar__link-title">
            {title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__subMenu-wrap">
          <ul className="sidebar__subMenu">
            <div>{children}</div>
          </ul>
        </Collapse>
      </div>
    );
  }
}

export default SidebarCategory;
