import React from "react";
import { Button } from "reactstrap";
import DataTable from "../../../shared/components/table/dataTable";
import { withRouter } from "react-router-dom";

const LocationsTable = ({ tableCardData, history }) => {
  const tableHeaders = [
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "site",
      headerName: "Site",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <a
          href={`/site/${params.row.site_id}/overview`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {params.row.site}
        </a>
      ),
    },
    {
      field: "total_devices",
      headerName: "Total Devices",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      type: "actions",
      renderCell: (params) => (
        <div className="material_data_table__cell_btn">
          <Button
            className="icon btn btn-small"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`/location/${params.row.id}`);
            }}
          >
            View Location
          </Button>
        </div>
      ),
      flex: 1,
      minWidth: 150,
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element) => {
        array.push({
          id: element.id,
          site_id: element.site_id,
          location: element.location,
          site: element.site,
          total_devices: element.total_devices,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} tableSize={10} disableFiltering={true} showToolbar={true} rowOnClickPath={"/location/id"} />;
};

export default withRouter(LocationsTable);
