import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Button, Spinner, Alert } from "reactstrap";
import { getUserOrganisationAction } from "../../user/redux/userActions";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

//Icons
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import { createLocationAction } from "../redux/locationActions";

const AddNewLocation = (props) => {
  //Local State
  const [modal, setModal] = useState(false);
  const [formError, setFormError] = useState(null);

  //Toggle modal visibility
  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const formValidation = Yup.object().shape({
    site_id: Yup.string().required("Please select a site"),
    name: Yup.string().min(3, "Location name is too short. Must be 3 characters or more").required("Location name is required"),
  });

  return (
    <React.Fragment>
      <Button className="icon btn" color="success" onClick={toggle}>
        <p>
          <PlusCircleIcon />
          Add Location
        </p>
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog">
        <div className="card__title">
          <h5 className="bold-text">Add a new location</h5>
          <h5 className="subhead">Fill out the details below to add a new location.</h5>
        </div>
        {formError && <Alert color="danger">{formError}</Alert>}
        <Formik
          initialValues={{
            site_id: "",
            name: "",
          }}
          validationSchema={formValidation}
          onSubmit={async (values) => {
            props
              .createLocationAction(values)
              .then(() => {
                //If the location was successfully added update the device list
                props
                  .getUserOrganisationAction()
                  .then()
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                setFormError(error.message);
              });
          }}
        >
          {({ handleChange }) => (
            <Form>
              <div className="form__form-group">
                <span className="form__form-group-label">Location Name</span>
                <div className="form__form-group-field">
                  <Field name="name" type="text" />
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="name" />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Site</span>
                <div className="form__form-group-field">
                  <Field name="site_id" as="select">
                    <option></option>
                    {props.sites &&
                      props.sites.map((site) => (
                        <option key={site.site.id} value={site.site.id}>
                          {site.site.name}
                        </option>
                      ))}
                  </Field>
                </div>
                <div className="form__error_message">
                  <ErrorMessage name="site_id" />
                </div>
              </div>
              <Button className="icon" color="success" type="submit">
                {props.isFetching ? (
                  <Spinner animation="border" className="btn-loading-spinner" />
                ) : (
                  <p>
                    <ContentSaveIcon /> Add Location
                  </p>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  createLocationAction: createLocationAction,
  getUserOrganisationAction: getUserOrganisationAction,
};

const mapStateToProps = (state) => ({
  isFetching: state.devices.isFetching,
  sites: state.user.sites,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewLocation));
