import { API_ROOT } from "../../config/config";
import { get, post, put } from "./base/index";

const getOrganisation = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/organisations/${params.organisation_id}`;
    return get(url, params);
  },
};

const getOrganisations = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/organisations/`;
    return get(url, params);
  },
};

const getRoles = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user-roles/`;
    return get(url, params);
  },
};

const registerOrganisation = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/organisations/`;
    return post(url, params);
  },
};

const enrollDevice = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/device/enroll/`;
    return post(url, params);
  },
};

const getDeviceModels = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/device-models/`;
    return get(url, params);
  },
};

const createSite = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site/create`;
    return post(url, params);
  },
};

const getUserSitePermissions = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site/permissions/${params.user_id}`;
    return get(url, params);
  },
};

const updateUserSitePermissions = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site/user/permissions`;
    return post(url, params);
  },
};

const enrollUser = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/enroll`;
    return post(url, params);
  },
};

const updateUser = {
  put: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/update`;
    return put(url, params);
  },
};

const getUsers = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/users`;
    return get(url, params);
  },
};

export {
  getOrganisation,
  getOrganisations,
  registerOrganisation,
  getRoles,
  enrollDevice,
  getDeviceModels,
  createSite,
  getUserSitePermissions,
  updateUserSitePermissions,
  enrollUser,
  updateUser,
  getUsers,
};
