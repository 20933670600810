import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import DeviceProfile from "./components/deviceProfile";
import BasicTable from "../table/components/BasicTable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDeviceMeasurementsAction, updateDeviceAction } from "../devices/redux/deviceActions";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";

//ICONS
import DeviceDetailsForm from "./components/deviceDetailsForm";
import ChartAreasplineIcon from "mdi-react/ChartAreasplineIcon";
import DeviceMeasurementsTable from "./components/deviceMeasurementsTable";
class DeviceDetails extends React.Component {
  constructor(props) {
    super();
    this.numMeasurements = 5;
    this.state = {
      toolTipOpen: false,
      modal: false,
    };
  }

  componentDidMount() {
    this.props.getDeviceMeasurementsAction(this.props.device_id, this.numMeasurements).catch((err) => {
      console.log(err);
    });
  }

  toggleToolTip = () => {
    this.setState({
      toolTipOpen: !this.state.toolTipOpen,
    });
  };

  render() {
    //if the device data has loaded, show the details page
    const device = this.props.activeDevices.filter((device) => device.id == this.props.device_id)[0];

    return this.props.isFetching ? (
      <LoadingSpinner loadingText="Loading device data..." />
    ) : (
      <React.Fragment>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{device.name}</h3>
            <h3 className="page-subhead subhead">View the device status and latest telemetry. You can also change the device name, location and view the device EUI.</h3>
          </Col>
        </Row>
        <Row>
          <Col xl={4} lg={4} md={12} sm={12}>
            <DeviceProfile device_model={device.model.name} device_last_seen={device.last_seen} device_battery={device.battery_level} />
          </Col>
          <Col xl={8} lg={8} md={12} sm={12}>
            <DeviceDetailsForm device={device} eui={device.eui} />
          </Col>
        </Row>
        <Row>
          <Col xl={9} lg={12} md={12} sm={12}>
            {device.active ? (
              this.props.measurements && (
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Latest Telemetry</h5>
                      <h5 className="subhead">The last 5 measurements from the device</h5>
                    </div>
                    <DeviceMeasurementsTable tableCardData={this.props.measurements} />
                  </CardBody>
                </Card>
              )
            ) : (
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Latest Telemetry</h5>
                    <h5 className="subhead">Device is offline. Re-activate the senor to start collecting data again.</h5>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col xl={3} lg={12} md={12} sm={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Device Actions</h5>
                </div>
                <div className="device_details__actions_btn_wrapper">
                  <Link
                    to={{
                      pathname: `/devices/${this.props.device_id}/dashboard`,
                      data: {
                        deviceName: device.name,
                        deviceId: this.props.device_id,
                      },
                    }}
                  >
                    <Button className="icon" color="primary">
                      <p>
                        <ChartAreasplineIcon />
                        View Device Dashboard
                      </p>
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeDevices: state.devices.activeDevices,
  isFetching: state.devices.isFetching,
  measurements: state.devices.measurementDetails,
});

const mapDispatchToProps = {
  updateDeviceAction: updateDeviceAction,
  getDeviceMeasurementsAction: getDeviceMeasurementsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
