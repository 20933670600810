import React from "react";
import { Container } from "reactstrap";
import ReportDetails from "../containers/reportDetails";

const ReportDetailsPage = (props) => (
  <Container className="dashboard">
    <ReportDetails reportId={props.match.params.id} />
  </Container>
);

export default ReportDetailsPage;
