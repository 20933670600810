import React from "react";
import { Alert, Spinner, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { updateUserSitePermissionsAction } from "../../redux/adminActions";
import ToastifyNotification from "../../../../shared/components/notification/index";

//Icons
import ContentSaveIcon from "mdi-react/ContentSaveIcon";
import ReactSwitch from "./reactSwitch";

class UsersPermissionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: null,
      permissions: [],
    };
  }

  formValidation = () =>
    Yup.object().shape({
      user: Yup.string().required("User email is required."),
    });

  handlePermissionChange = (value) => {
    //If permission already exists in the array update it else add it
    const permissionArray = this.state.permissions;
    const i = permissionArray.findIndex((i) => i.id === value.id);
    if (i > -1) permissionArray[i] = value;
    // (2)
    else permissionArray.push(value);

    //Update state with new array
    this.setState({
      permissions: permissionArray,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            user: this.props.user,
            permissions: "",
          }}
          validationSchema={this.formValidation}
          onSubmit={async (values, { resetForm }) => {
            values.permissions = this.state.permissions;
            this.props
              .updateUserSitePermissionsAction(values)
              .then(() => {
                ToastifyNotification("User permissions updated. The user will need to log out for these changes to take full effect");
              })
              .catch((error) => {
                this.setState({ formError: error.message });
                resetForm();
              });
          }}
        >
          {({ handleChange }) => (
            <Form>
              {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
              <div className="form__form-group">
                <div className="form__from-switch-wrapper">
                  {this.props.permissions &&
                    this.props.permissions.map((permission, index) => (
                      <div key={index}  className="form__form-switch">
                        <React.Fragment>
                          <span >{permission.name}</span>
                          <ReactSwitch onChange={this.handlePermissionChange} permission_id={permission.id} checked={permission.user_has_permission} />
                        </React.Fragment>
                      </div>
                    ))}
                </div>
              </div>
              <Button className="icon" color="success" type="submit">
                {this.props.isFetching ? (
                  <Spinner animation="border" className="btn-loading-spinner" />
                ) : (
                  <p>
                    <ContentSaveIcon /> Update Permissions
                  </p>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateUserSitePermissionsAction: updateUserSitePermissionsAction,
};

const mapStateToProps = (state) => ({
  userOrganisation: state.user.organisation.id,
  permissions: state.admin.userSitePermissions,
  isFetching: state.admin.isFetching,
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPermissionsForm);
