import React, { useState } from "react";
import { Alert, Spinner, Card, CardBody } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { passwordResetViaTokenAction } from "../redux/userActions";

//Icons
import EyeIcon from "mdi-react/EyeIcon";

const ResetPasswordViaToken = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(null);
  const [formError, setFormError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  const formValidation = Yup.object().shape({
    password: Yup.string()
      .required("Password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords do not match")
      .required("Confirm password is required"),
  });

  return (
    <div className="login">
      <div className="login__wrapper">
        <Card className="login__card">
          <CardBody>
            <div className="card__title">
              <h3 className="login__title">Password Reset</h3>
              <h5 className="subhead">Please enter and confirm your new password below</h5>
            </div>
            <React.Fragment>
              <Formik
                initialValues={{
                  token: props.match.params.token,
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={formValidation}
                onSubmit={async (values) => {
                  props
                    .passwordResetViaTokenAction(values)
                    .then(() => {
                      setSuccessMessage("Your password has been changed.\n Please wait while you are redirected to login");
                      setTimeout(() => {
                        props.history.push("/login");
                      }, 5000);
                      //Reset the form and remove any errors
                      setFormError(null);
                    })
                    .catch((error) => {
                      setFormError("error");
                    });
                }}
              >
                <Form>
                  {successMessage && <Alert color="success">{successMessage}</Alert>}
                  {formError && <Alert color="danger">{formError}</Alert>}
                  <div className="form__form-group">
                    <span className="form__form-group-label">Password</span>
                    <div className="form__form-group-field">
                      <Field name="password" type={showPassword ? "text" : "password"} placeholder="Password" autoComplete="off"/>
                      <button className={`form__form-group-button${showPassword ? " active" : ""}`} onClick={(e) => togglePassword(e)} type="button">
                        <EyeIcon />
                      </button>
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Confirm Password</span>
                    <div className="form__form-group-field">
                      <Field name="confirmPassword" type={showConfirmPassword ? "text" : "password"} placeholder="Password" autoComplete="off" />
                      <button className={`form__form-group-button${showConfirmPassword ? " active" : ""}`} onClick={(e) => toggleConfirmPassword(e)} type="button">
                        <EyeIcon />
                      </button>
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="confirmPassword" />
                    </div>
                  </div>
                  <button className="btn btn-primary login__login_btn" type="submit">
                    {props.isFetching ? <Spinner animation="border" className="login__loading_spinner" /> : "Update Password"}
                  </button>
                </Form>
              </Formik>
            </React.Fragment>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  passwordResetViaTokenAction: passwordResetViaTokenAction,
};

const mapStateToProps = (state) => ({
  isFetching: state.user.isFetching,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordViaToken));
