import React from "react";
import { Col, Container, Row } from "reactstrap";
import Heatmap from "../containers/dashboards/heatmap";

const HeatmapDashboardView = () => (
  <Container className="dashboard">
    <Row>
      <Col lg="12" md="12" sm="12">
        <h3 className="page-title">Heatmap of all device measurements</h3>
        <h3 className="page-subhead subhead">This heatmap displays the average measurements collected over the previous 24hrs.</h3>
      </Col>
    </Row>
    <Heatmap />
  </Container>
);

export default HeatmapDashboardView;
