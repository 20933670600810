import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

const LogOut = withRouter((props) => {
  const dispatch = useDispatch();
  dispatch({ type: "LOGOUT" });
  localStorage.clear();
  props.history.push("/");
  return null;
});

export default LogOut;
