import axios, { defaultParams } from "./axios";

const _delete = async (url, params) => {
  try {
    return await axios.delete(url, { ...defaultParams(), ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    try {
      return axios.delete(url, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};

export default _delete;
