import React from "react";
import { Row, Col, Spinner, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";

//Icons
import { getReportAction } from "./redux/reportActions";
import AddNewReportModel from "./components/addNewReportModel";
import ReportsTable from "./components/reportTable";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getReportAction();
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <AddNewReportModel />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>{this.props.isFetching ? <Spinner /> : this.props.reports ? <ReportsTable tableCardData={this.props.reports} /> : "No data"}</CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.reports.isFetching,
  reports: state.reports.reports,
  organisation: state.user.organisation,
});

const mapDispatchToProps = {
  getReportAction: getReportAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
