import { API_ROOT } from "../../config/config";
import { post, get, _delete } from "./base/index";

const login = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/login`;
    return post(url, params);
  },
};

const register = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/register`;
    return post(url, params);
  },
};

const resetPassword = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/reset_password`;
    return post(url, params);
  },
};

const getUsersOrganisation = {
  get: (data) => {
    const params = { user_organisation: 1 };
    const url = `${API_ROOT}/organisations/${params.user_organisation}`;
    return get(url, params);
  },
};

const deleteUser = {
  _delete: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/users/${params.user_id}`;
    return _delete(url, params);
  },
};

const userActivation = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/activation`;
    return post(url, params);
  },
};

const userRequestPasswordReset = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/password_reset/request`;
    return post(url, params);
  },
};

const userPasswordResetViaToken = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/user/reset_password/token`;
    return post(url, params);
  },
};

export { login, register, resetPassword, getUsersOrganisation, deleteUser, userActivation, userRequestPasswordReset, userPasswordResetViaToken };
