import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { connect } from "react-redux";

//Menu icons are from https://linearicons.com/free
class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { user } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarCategory title="Dashboards" icon="chart-bars">
            <SidebarLink title="Current Measurements" route="/dashboard/current-measurements" onClick={this.hideSidebar} />
            <SidebarLink title="Previous 24hrs Heatmap" route="/dashboard/previous-24hrs-heatmap" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Sites" icon="map-marker" route="/sites" onClick={this.hideSidebar} />
          <SidebarLink title="Locations" icon="location" route="/locations" onClick={this.hideSidebar} />
          <SidebarLink title="Devices" icon="laptop-phone" route="/devices" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Reports" icon="book" route="/reports" onClick={this.hideSidebar} />
        </ul>
        {(user.role.id === 1 || user.role.id === 2) && (
          <ul className="sidebar__block">
            {(user.role.id === 1 || user.role.id === 2) && <SidebarLink title="Manage Users" icon="users" route="/admin/users" onClick={this.hideSidebar} />}
            <SidebarCategory title="Administration" icon="lock">
              {user.role.id === 1 && <SidebarLink title="Add New Organisation" route="/admin/new-organisation" onClick={this.hideSidebar} />}
              {user.role.id === 1 && <SidebarLink title="Enroll Device" route="/admin/enroll-device" onClick={this.hideSidebar} />}
              {user.role.id === 1 && <SidebarLink title="Add New User" route="/admin/new-user" onClick={this.hideSidebar} />}
            </SidebarCategory>
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(SidebarContent);
