import { API_ROOT } from "../../config/config";
import { get } from "./base/index";

const getSiteAverages = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site/${params.site_id}/averages`;
    return get(url, params);
  },
};

const getSiteLocations = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site/${params.site_id}/locations`;
    return get(url, params);
  },
};

const getSiteLocationPerformance = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/site/locations/${params.site_id}/performance`;
    return get(url, params);
  },
};

const getAllSites = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/sites`;
    return get(url, params);
  },
};

export { getSiteAverages, getSiteLocations, getSiteLocationPerformance, getAllSites };
