import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const ToastifyNotification = (text) => {
  return toast.success(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default ToastifyNotification;
