import { handleActions } from "redux-actions";
import {
  getOrganisationsFailure,
  getOrganisationsRequest,
  getOrganisationsSuccess,
  getRolesRequest,
  getRolesSuccess,
  getRolesFailure,
  getModelsRequest,
  getModelsSuccess,
  getModelsFailure,
  registerOrganisationFailure,
  registerOrganisationRequest,
  registerOrganisationSuccess,
  enrollUserRequest,
  enrollUserSuccess,
  enrollUserFailure,
  enrollDeviceRequest,
  enrollDeviceSuccess,
  enrollDeviceFailure,
  createSiteRequest,
  createSiteSuccess,
  createSiteFailure,
  getUserSitePermissionsRequest,
  getUserSitePermissionsSuccess,
  getUserSitePermissionsFailure,
  updateUserSitePermissionsRequest,
  updateUserSitePermissionsSuccess,
  updateUserSitePermissionsFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
} from "./adminActions";

const defaultState = {
  organisationList: null,
  roleList: null,
  deviceModelList: null,
  userSitePermissions: null,
  isFetching: false,
  error: null,
  users: null,
};

export default handleActions(
  {
    [getOrganisationsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getOrganisationsSuccess](state, { payload }) {
      return {
        ...state,
        organisationList: Array.isArray(payload.organisations) ? payload.organisations : [payload.organisations],
        isFetching: false,
        error: null,
      };
    },
    [getOrganisationsFailure](state, { payload }) {
      return {
        ...state,
        organisationList: null,
        isFetching: false,
        error: payload,
      };
    },
    [getRolesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getRolesSuccess](state, { payload }) {
      return {
        ...state,
        roleList: payload.roles,
        isFetching: false,
        error: null,
      };
    },
    [getRolesFailure](state, { payload }) {
      return {
        ...state,
        roleList: null,
        isFetching: false,
        error: payload,
      };
    },
    [registerOrganisationRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [registerOrganisationSuccess](state, { payload }) {
      return {
        ...state,
        organisationList: state.organisationList == null ? [payload] : [...state.organisationList, payload],
        isFetching: false,
        error: null,
      };
    },
    [registerOrganisationFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },

    // Add User reducers
    [enrollUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [enrollUserSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [enrollUserFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },

    // Add Device reducers
    [enrollDeviceRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [enrollDeviceSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [enrollDeviceFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [getModelsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getModelsSuccess](state, { payload }) {
      return {
        ...state,
        deviceModelList: payload.deviceModelList,
        isFetching: false,
        error: null,
      };
    },
    [getModelsFailure](state, { payload }) {
      return {
        ...state,
        deviceModelList: null,
        isFetching: false,
        error: payload,
      };
    },
    [createSiteRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [createSiteSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [createSiteFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getUserSitePermissionsRequest](state) {
      return {
        ...state,
        //isFetching: true,
        error: null,
      };
    },
    [getUserSitePermissionsSuccess](state, { payload }) {
      return {
        ...state,
        userSitePermissions: payload,
        //isFetching: false,
        error: null,
      };
    },
    [getUserSitePermissionsFailure](state, { payload }) {
      return {
        ...state,
        //isFetching: false,
        error: payload,
      };
    },
    [updateUserSitePermissionsRequest](state) {
      return {
        ...state,
        //isFetching: true,
        error: null,
      };
    },
    [updateUserSitePermissionsSuccess](state, { payload }) {
      return {
        ...state,
        //isFetching: false,
        error: null,
      };
    },
    [updateUserSitePermissionsFailure](state, { payload }) {
      return {
        ...state,
        //isFetching: false,
        error: payload,
      };
    },
    [deleteUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [deleteUserSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [deleteUserFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [updateUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateUserSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [updateUserFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getUsersRequest](state) {
      return {
        ...state,
        isFetching: true,
        users: null,
        error: null,
      };
    },
    [getUsersSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        users: payload,
        error: null,
      };
    },
    [getUsersFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        users: null,
        error: payload,
      };
    },
  },
  defaultState
);
