import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopBarMenuLink from "./TopBarMenuLink";
import Ava from "../../../../assets/img/profile-avatar.png";

class TopBarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;
    const { user } = this.props;

    return (
      <div className="topBar__profile">
        <button type="button" className="topBar__avatar" onClick={this.toggle}>
          <img className="topBar__avatar-img" src={Ava} alt="avatar" />
          <p className="topBar__avatar-name">{user.email}</p>
          <DownIcon className="topBar__icon" />
        </button>
        {collapse && <button type="button" className="topBar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topBar__menu-wrap">
          <div className="topBar__menu">
            <TopBarMenuLink title="User Profile" icon="list" path="/user" />
            <div className="topBar__menu-divider" />
            <TopBarMenuLink title="Log Out" icon="exit" path="/logout" />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(TopBarProfile);
