import React from "react";
import { Button } from "reactstrap";
import DataTable from "../../../shared/components/table/dataTable";
import { withRouter } from "react-router-dom";

const ReportsTable = ({ tableCardData, history }) => {
  const tableHeaders = [
    {
      field: "name",
      headerName: "Report Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 150,
      renderCell: (params) => (
        <div className="material_data_table__cell_btn">
          <Button
            className="icon btn btn-small"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`/reports/${params.row.id}`);
            }}
          >
            View report
          </Button>
        </div>
      ),
    },
  ];

  const createData = (data) => {
    var array = [];
    if (data && data.length > 0) {
      data.forEach((element) => {
        array.push({
          id: element.id,
          name: element.name,
        });
      });
    }
    return array;
  };

  return <DataTable columns={tableHeaders} rows={createData(tableCardData)} rowOnClickPath={"/reports/id"} tableSize={5} disableFiltering={true} showToolbar={true} />;
};

export default withRouter(ReportsTable);
