import React from "react";
import { Col, Container, Row } from "reactstrap";
import UserDetails from "../../containers/admin/userDetails";

const UserDetailsView = (props) => {
  const user_id = props.match.params.id

  return(
    <Container className="dashboard">
      <Row>
        <Col lg="12" md="12" sm="12">
          <h3 className="page-title">User Details</h3>
          <h3 className="page-subhead subhead">View and edit the user below</h3>
        </Col>
      </Row>
      <UserDetails user_id={user_id}/>
    </Container>
  )
};

export default UserDetailsView;
