import React from "react";
import { Card, CardBody } from "reactstrap";
import deviceImage from "../../../assets/img/am107.png";
import BatteryIcon from "mdi-react/BatteryIcon";
import PropTypes from "prop-types";
import classNames from "classnames";

const DeviceProfile = ({ device_model, device_status, device_battery, device_last_seen }) => {
  return (
    <Card>
      <CardBody className="device_details__card_body">
        <div className="device_details__details_wrapper">
          <div className="device_details__device_profile">
            <img src={deviceImage} alt="MileSight am107" />
            <div className="device_details__device_status">
              <span className={classNames("status", { online: device_last_seen, offline: !device_last_seen })}>
                <strong style={{ paddingRight: "5px" }}>Last seen: </strong> {device_last_seen ? device_last_seen : "Never seen"}
              </span>
              {device_battery ? (
                <div className="battery">
                  <BatteryIcon />
                  <span>{device_battery}%</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

DeviceProfile.propTypes = {
  device_model: PropTypes.string.isRequired,
  device_battery: PropTypes.number,
  device_status: PropTypes.string.isRequired,
};

DeviceProfile.defaultProps = {
  device_model: "",
  device_battery: "",
  device_status: "",
};

export default DeviceProfile;
