import React from "react";
import { Col, Container, Row } from "reactstrap";
import DeviceDashboard from "../containers/deviceDashboard";

const SingleDeviceDashboard = (props) => {
  const device_id = props.match.params.id;

  return (
    <Container className="dashboard">
      <DeviceDashboard device_id={device_id} />
    </Container>
  );
};

export default SingleDeviceDashboard;
