import React from "react";
import { Col, Container, Row } from "reactstrap";
import Site from "../containers/siteOverview";
import { connect } from "react-redux";

const SiteOverviewView = (props) => {
  const site = props.sites.filter((site) => site.site.id == props.match.params.id)[0];

  return (
    <Container className="dashboard">
      <Row>
        <Col lg="12" md="12" sm="12">
          <h3 className="page-title">{site.site.name} Overview</h3>
          <h3 className="page-subhead subhead">Below is a overview of the site</h3>
        </Col>
      </Row>
      <Site site_id={props.match.params.id} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  sites: state.user.sites,
});

export default connect(mapStateToProps, null)(SiteOverviewView);
