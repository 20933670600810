import { handleActions } from "redux-actions";
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  getUserOrganisationRequest,
  getUserOrganisationSuccess,
  getUserOrganisationFailure,
  activateUserRequest,
  activateUserSuccess,
  activateUserFailure,
  requestPasswordResetRequest,
  requestPasswordResetSuccess,
  requestPasswordResetFailure,
  passwordResetViaTokenRequest,
  passwordResetViaTokenSuccess,
  passwordResetViaTokenFailure,
} from "./userActions";

const defaultState = {
  user: null,
  organisation: null,
  sites: null,
  isAuthenticated: false,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [loginRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [loginSuccess](state, { payload }) {
      return {
        ...state,
        isAuthenticated: true,
        user: payload.user,
        organisation: payload.user.organisation,
        sites: payload.user.sites,
        isFetching: false,
        error: null,
      };
    },
    [loginFailure](state, { payload }) {
      return {
        ...state,
        user: null,
        organisation: null,
        isAuthenticated: false,
        isFetching: false,
        error: payload,
      };
    },
    [registerRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [registerSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [registerFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [resetPasswordRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [resetPasswordSuccess](state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [resetPasswordFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [getUserOrganisationRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getUserOrganisationSuccess](state, { payload }) {
      return {
        ...state,
        organisation: payload.organisation,
        sites: payload.sites,
        isFetching: false,
        error: null,
      };
    },
    [getUserOrganisationFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload.error.response.data.error,
      };
    },
    [activateUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [activateUserSuccess](state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [activateUserFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [requestPasswordResetRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [requestPasswordResetSuccess](state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [requestPasswordResetFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [passwordResetViaTokenRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [passwordResetViaTokenSuccess](state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    },
    [passwordResetViaTokenFailure](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
