import { handleActions } from "redux-actions";
import {
  latestMeasurementsAllDevicesRequest,
  latestMeasurementsAllDevicesSuccess,
  latestMeasurementsAllDevicesFailure,
  aggregatedData24hrsRequest,
  aggregatedData24hrsSuccess,
  aggregatedData24hrsFailure,
} from "./dashboardActions";

const defaultState = {
  latest_measurements: null,
  aggregatedData24hrs: null,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [latestMeasurementsAllDevicesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [latestMeasurementsAllDevicesSuccess](state, { payload }) {
      let latest = payload.measurements;
      return {
        ...state,
        latest_measurements: latest.length > 0 ? latest : null,
        isFetching: false,
        error: null,
      };
    },
    [latestMeasurementsAllDevicesFailure](state, { payload }) {
      return {
        ...state,
        latest_measurements: null,
        isFetching: false,
        error: payload,
      };
    },
    [aggregatedData24hrsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [aggregatedData24hrsSuccess](state, { payload }) {
      return {
        ...state,
        aggregatedData24hrs: payload.measurements,
        isFetching: false,
        error: null,
      };
    },
    [aggregatedData24hrsFailure](state, { payload }) {
      return {
        ...state,
        aggregatedData24hrs: null,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
