import { API_ROOT } from "../../config/config";
import { post, get, _delete } from "./base/index";

const createReport = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/create/`;
    return post(url, params);
  },
};

const getReport = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/get/`;
    return get(url, params);
  },
};

const runReport = {
  post: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/run/`;
    return post(url, params);
  },
};

const getAllReportRuns = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/${params.report_id}/runs`;
    return get(url, params);
  },
};

const getReportRun = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/${params.report_id}/run/${params.report_run_id}`;
    return get(url, params);
  },
};

const downloadReport = {
  get: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/${params.report_run_id}/download`;
    return get(url, params);
  },
};

const deleteReport = {
  _delete: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/delete/${params.report_id}`;
    return _delete(url, params);
  },
};

const deleteReportRun = {
  _delete: (data) => {
    const params = { ...data };
    const url = `${API_ROOT}/report/run/${params.report_run_id}/delete`;
    return _delete(url, params);
  },
};

export { createReport, getReport, runReport, getAllReportRuns, getReportRun, downloadReport, deleteReport, deleteReportRun };
