import React from "react";
import Chart from "react-apexcharts";

const GuageChart = (props) => {
  const options = {
    series: [(props.value * 100) / props.threshold],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
              formatter: () => {
                return `${props.value}${props.unit}`;
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        color: "#32a5c7",
      },
      labels: ["Average Results"],
    },
  };

  return <Chart options={options.options} series={options.series} type="radialBar" height={175} />;
};

export default GuageChart;
