import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Alert } from "reactstrap";
import { Form, Formik, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import ToastifyNotification from "../../../shared/components/notification";

//Icons
import FlashIcon from "mdi-react/FlashIcon";

import DatePicker from "../../reports/components/datePicker";
import { runReportAction, getAllReportRunsAction } from "../../reports/redux/reportActions";

class RunReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: false,
      formError: null,
    };
  }

  formValidation = () =>
    Yup.object().shape({
      dateRange: Yup.object()
        .shape({
          startDate: Yup.string().required("Please select a time frame"),
          endDate: Yup.string().required("Please select a time frame"),
          key: Yup.string().required("Please select a time frame"),
        })
        .required("Please select a time frame"),
    });

  toggleModal = () => {
    this.setState((prevState) => ({
      model: !prevState.model,
    }));

    //
  };

  setFormError = (error) => {
    this.setState(() => ({
      formError: error,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Button className="icon" color="success" type="submit" onClick={this.toggleModal}>
          <p>
            <FlashIcon /> Run Report
          </p>
        </Button>
        <Modal isOpen={this.state.model} toggle={this.toggleModal} className="modal-dialog">
          <React.Fragment>
            <div className="card__title">
              <h5 className="bold-text">Run Report: {this.props.reportName}</h5>
              <h5 className="subhead">
                Select the dates below and run the report. The report will complete in the background. Once completed you will see it in the "Previous report runs" section
              </h5>
            </div>
            <Formik
              initialValues={{
                reportId: this.props.reportId,
                dateRange: { startDate: new Date(), endDate: new Date(), key: "selection" },
              }}
              validationSchema={this.formValidation}
              onSubmit={async (values) => {
                console.log(values);
                this.props
                  .runReportAction(values)
                  .then(() => {
                    this.props.getAllReportRunsAction({ report_id: this.props.reportId }).then(() => {
                      this.toggleModal();
                      ToastifyNotification("Report has been queued, check the status below");
                    });
                  })
                  .catch((error) => {
                    //Login error
                    this.setFormError(error.message);
                  });
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  {this.state.formError && <Alert color="danger">{this.state.formError}</Alert>}
                  <div className="form__form-group">
                    <p className="form__form-group-label">Select the date(s) you want data from</p>
                    <div className="form__form-group-field report__date_picker">
                      <DatePicker name="dateRange" onChange={setFieldValue} />
                    </div>
                    <div className="form__error_message">
                      <ErrorMessage name="dateRange" />
                    </div>
                  </div>
                  <Button className="icon" color="success" type="submit">
                    <p>
                      <FlashIcon /> Run
                    </p>
                  </Button>
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  runReportAction: runReportAction,
  getAllReportRunsAction: getAllReportRunsAction,
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RunReportModal));
