import axios from "axios";
import { API_ROOT } from "../../../config/config";

export const defaultParams = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
});

const axiosInstance = axios.create({
  headers: {
    Authorization: localStorage.getItem("access") ? "Bearer " + localStorage.getItem("access") : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
  timeout: 35000,
  baseURL: API_ROOT,
});

axiosInstance.interceptors.response.use(
  async (response) => response, // if the request is successful, return as normal
  async (error) => {
    // if the request fails, respond in the event that the access token has expired.
    const originalRequest = error.config;

    if (typeof error.response === "undefined") {
      // alert(
      // 	'A server/network error occurred. ' +
      // 		'Looks like CORS might be the problem. ' +
      // 		'Sorry about this - we will get it fixed shortly.'
      // );
      return Promise.reject(error);
    }

    // If it's not a 401 Unauthorized, then it's some other error -- redirect to login.
    if (error.response.status !== 401) {
      //window.location.href = '/login/';
      return Promise.reject(error);
    }

    // If it's a 401 and the original URL is the refresh token endpoint, redirect to login to prevent potential infinite request loop
    if ((error.response.status === 401 && originalRequest.url === `${API_ROOT}/refresh`) || originalRequest.url == `${API_ROOT}/user/login`) {
      //window.location.href = '/login/';
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem("refresh");
    if (refreshToken) {
      // check the token's expiry date is greater than the current time
      const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));
      // exp date in token is expressed in seconds, while now() returns milliseconds:
      const now = Math.ceil(Date.now() / 1000);
      if (tokenParts.exp > now) {
        // if the refresh token hasn't expired, send a POST to the refresh endpoint, and persist the new access token that's returned
        return axiosInstance.post("refresh", { refresh: refreshToken }, { headers: { Authorization: `Bearer ${refreshToken}` } }).then((response) => {
          localStorage.setItem("access", response.data.access_token);
          axiosInstance.defaults.headers["Authorization"] = "Bearer " + response.data.access_token;
          originalRequest.headers["Authorization"] = "Bearer " + response.data.access_token;
          return axiosInstance(originalRequest);
        });
      } else {
        console.log("Refresh token is expired");
        //window.location.href = '/login/';
      }
    } else {
      console.log("Refresh token not available.");
      //window.location.href = '/login/';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
