import React from "react";
import LogInForm from "./components/LogInForm";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const LogIn = () => {
  return (
    <div className="login">
      <div className="login__wrapper">
        <Card className="login__card">
          <CardBody>
            <div className="card__title">
              <h3 className="login__title">Welcome to the IoT Platform</h3>
              <h5 className="subhead">Login to your account</h5>
            </div>
            <LogInForm />
            <Link to="/password/reset/request">
              <span className="forgot_password">Forgot Password?</span>
            </Link>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default LogIn;
