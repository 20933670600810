import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar, Modal } from "reactstrap";

const ModalComponent = ({ title, children, modal, onClick, toggle, subtitle }) => {
  return (
    <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--header`}>
      <div className="card__title">
        <h5 className="bold-text">{title}</h5>
        <h5 className="subhead">{subtitle}</h5>
      </div>
      <div className="modal__body">{children}</div>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel" onClick={toggle}>
          Cancel
        </Button>
        <Button className="modal_ok" color="success" onClick={onClick}>
          Ok
        </Button>
      </ButtonToolbar>
    </Modal>
  );
};

ModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  modal: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  toggle: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

export default ModalComponent;
