import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { connect } from "react-redux";

import Layout from "../../shared/components/Layout/index";

import LogIn from "../../containers/user/login/index";
import LogOut from "../../containers/user/logOut/index";
import userProfile from "../../views/userProfile";
import DevicesPage from "../../views/devices";
import DeviceDetailsPage from "../../views/deviceDetails";
import SingleDeviceDashboardPage from "../../views/singleDeviceDashboard";
import LatestMeasurementsDashboardView from "../../views/latestMeasurementsDashboardView";
import HeatmapDashboardView from "../../views/heatmapDashboardView";
import NewOrganisation from "../../views/admin/newOrganisation";
import EnrollDeviceView from "../../views/admin/enrollDevice";
import AdminRoute from "./AdminRoute";
import ReportsPage from "../../views/reports";
import ReportDetailsPage from "../../views/reportDetails";
import SiteOverviewView from "../../views/siteOverview";
import LocationsPage from "../../views/locations";
import LocationOverviewPage from "../../views/locationOverview";
import ManageUsersView from "../../views/admin/users";
import UserDetailsView from "../../views/admin/userDetails";
import SitesView from "../../views/sites";
import UserActivation from "../../containers/user/userActivation";
import ResetPasswordRequestForm from "../../containers/user/resetPassword/components/resetPasswordRequestForm";
import ResetPasswordViaToken from "../../containers/user/resetPassword";
import AddNewUserView from "../../views/admin/addNewUser";

const defaultViewRoute = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={views} />
    </div>
  </div>
);

const adminViewRoute = () => (
  <Switch>
    <Route path="/admin/new-organisation" component={NewOrganisation} />
    <Route path="/admin/enroll-device" component={EnrollDeviceView} />
    <Route path="/admin/users" component={ManageUsersView} />
    <Route path="/admin/user/:id" component={UserDetailsView} />
    <Route path="/admin/new-user" component={AddNewUserView} />
  </Switch>
);

const views = () => (
  <Switch>
    <Route path="/site/:id/overview" component={SiteOverviewView} />
    <Route path="/devices/:id/dashboard" component={SingleDeviceDashboardPage} />
    <Route path="/devices/:id" component={DeviceDetailsPage} />
    <Route path="/devices" component={DevicesPage} />
    <Route path="/dashboard/current-measurements" component={LatestMeasurementsDashboardView} />
    <Route path="/dashboard/previous-24hrs-heatmap" component={HeatmapDashboardView} />
    <Route path="/user" component={userProfile} />
    <Route path="/reports/:id" component={ReportDetailsPage} />
    <Route path="/reports" component={ReportsPage} />
    <Route path="/locations" component={LocationsPage} />
    <Route path="/location/:id" component={LocationOverviewPage} />
    <Route path="/sites" component={SitesView} />
    <AdminRoute path="/admin" component={adminViewRoute} />
  </Switch>
);

const Router = () => (
  <main>
    <Switch>
      <Route exact path="/" component={LogIn} />
      <Route exact path="/login" component={LogIn} />
      <Route exact path="/logout" component={LogOut} />
      <Route exact path="/activation/:token" component={UserActivation} />
      <Route exact path="/password/reset/request" component={ResetPasswordRequestForm} />
      <Route exact path="/password/reset/:token" component={ResetPasswordViaToken} />
      <ProtectedRoute path="/" component={defaultViewRoute} />
    </Switch>
  </main>
);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Router);
