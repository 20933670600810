import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import CloseIcon from "mdi-react/CloseIcon";
import SearchIcon from "mdi-react/SearchIcon";
import { Collapse, Button } from "reactstrap";
import MenuIcon from "mdi-react/MenuIcon";

const CustomToolbar = (props) => {
  const [mobileFilterMenu, setMobileFilterMenu] = useState(false);

  const toggleMobileFilterMenu = () => {
    setMobileFilterMenu(!mobileFilterMenu);
  };

  return (
    <React.Fragment>
      <div className="MuiDataGrid_Toolbar__custom desktop">
        <div style={{ display: "flex" }}>
          <GridToolbarFilterButton className="MuiDataGrid_filter_button" />
          <TextField
            className="MuiDataGrid_search_input__custom"
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" style={{ marginRight: "5px" }} />,
              endAdornment: (
                <IconButton title="Clear" aria-label="Clear" size="small" style={{ visibility: props.value ? "visible" : "hidden" }} onClick={props.clearSearch}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </div>
        <GridToolbarExport
          className="MuiDataGrid_export__custom"
          printOptions={{ hideFooter: true, hideToolbar: true, fileName: "iot-data-export" }}
          csvOptions={{ fileName: "iot-data-export", allColumns: true }}
        />
      </div>
      <div className="MuiDataGrid_Toolbar__custom mobile">
        <div className="MuiDataGrid_Toolbar__menu_toggle">
          <div className="mobile_toggle_btn">
            <Button className="icon" color="primary" type="submit" onClick={toggleMobileFilterMenu}>
              <MenuIcon />
            </Button>
          </div>
          <Collapse className="MuiDataGrid_Toolbar__mobile_filter_menu" isOpen={mobileFilterMenu}>
            <div className="MuiDataGrid_Toolbar__mobile_filter_menu_filters">
              <TextField
                className="MuiDataGrid_search_input__custom"
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" style={{ marginRight: "5px" }} />,
                  endAdornment: (
                    <IconButton title="Clear" aria-label="Clear" size="small" style={{ visibility: props.value ? "visible" : "hidden" }} onClick={props.clearSearch}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
              <GridToolbarFilterButton className="MuiDataGrid_filter_button" />

              <GridToolbarExport
                className="MuiDataGrid_export__custom"
                printOptions={{ hideFooter: true, hideToolbar: true, fileName: "iot-data-export" }}
                csvOptions={{ fileName: "iot-data-export" }}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomToolbar;
