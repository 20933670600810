import React from "react";
import { Col, Card, CardBody, Row, Button, Spinner } from "reactstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../shared/components/spinner/loading_spinner";
import SitesTable from "./components/sitesTable";
import AddNewSite from "./components/addNewSite";
import { getAllSitesAction } from "./redux/siteActions";

class Sites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getAllSitesAction();
  }

  render() {
    return this.props.isFetching ? (
      <LoadingSpinner loadingText="Loading devices..." />
    ) : (
      <React.Fragment>
        <Row>
          <Col>
            <AddNewSite />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>{this.props.isFetching ? <Spinner /> : <SitesTable tableCardData={this.props.sites} />}</CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: state.site.isFetching,
  sites: state.site.sites,
});

const mapDispatchToProps = {
  getAllSitesAction: getAllSitesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
